import {messengerApi, notificationsApi} from '@/api';
import {call, SagaReturnType, put, all, takeLeading, take} from 'redux-saga/effects';
import {messengerActions as actions, messengerActions} from './slice';
import {connectClient} from './helpers';
import {SagaPayload} from '../types';
import {errorHandler} from '../errorHandler';

export function * messengerSaga () {
  yield all([
    takeLeading(actions.requestMessengerStatistic, requestMessengerStatistic),
    takeLeading(actions.requestConnectionConfigure, requestConnectionConfigure),
    takeLeading(actions.requestConnectionClose, requestConnectionClose),
  ]);
}

export function * requestMessengerStatistic (): SagaReturnType<any> {
  try {
    const count: Awaited<ReturnType<typeof messengerApi.getMessengerStatistic>> = yield call(messengerApi.getMessengerStatistic);
    yield put(actions.setMessengerData(count));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  }
}

export function * requestConnectionConfigure (): SagaReturnType<any> {
  try {
    const connectionConfigure: Awaited<ReturnType<typeof notificationsApi.createConnection>> = yield call(notificationsApi.createConnection);
    const channel = yield call(connectClient, connectionConfigure);
    while (true) {
      const action = yield take(channel);
      yield put(action);
    }
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  }
}

export function * requestConnectionClose ({payload}: SagaPayload<typeof messengerActions.requestConnectionClose>): SagaReturnType<any> {
  try {
    yield call(notificationsApi.closeConnection, payload.id);
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  }
}
