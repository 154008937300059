import {createSlice} from '@reduxjs/toolkit';
import {Nullable} from '@/types';

interface SliceProps {
  user: Nullable<Record<string, unknown>>
  token: Nullable<unknown>
  isAboutSystemModalOpen: boolean
}
const initialState: SliceProps = {user: null, token: null, isAboutSystemModalOpen: false};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const {user, token} = action.payload;
      state.user = user;
      state.token = token;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
    },
    handleAboutSystemModal: (state) => {
      state.isAboutSystemModalOpen = !state.isAboutSystemModalOpen;
    },
  },
});

export const {actions} = userSlice;

export const userReducer = userSlice.reducer;
export const userStoreName = userSlice.name;

// const selectCurrentUser: Selector<RootState, ISlice['user']> = (state) => state.user.user;
// const selectCurrentToken: Selector<RootState, ISlice['token']> = (state) => state.user.token;

export const selectors = {
  // selectCurrentUser,
  // selectCurrentToken,
};
