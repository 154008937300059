/** @jsxImportSource @emotion/react */
import {Theme} from '@mui/material';
import IconClose from '@/shared/assets/icons/18x18/close.svg?react';
import IconError from '@/shared/assets/icons/error.svg?react';
import IconWarn from '@/shared/assets/icons/warning.svg?react';
import IconSVGSuccess from '@/shared/assets/images/icons/icon_success_round.svg?react';
import {H5, Text} from '@/components/Text';
import {useTranslate} from '@/i18n/useTranslate';
import {FC} from 'react';
import {toast, ToastOptions} from 'react-toastify';
import {IconButtonNew} from 'ui-kit';

import {customErrorStyles, customSuccessStyles, customWarnStyles} from './styled';
import {ToastContentProps, ToastDataProps} from './types';

const IconCloseBtn = () => {
  return (
    <IconButtonNew size='xs' color='quaternary'>
      <IconClose />
    </IconButtonNew>
  );
};

const ErrorToastContent: FC<ToastContentProps> = ({header, text}) => {
  const {t} = useTranslate('common');
  return (
    <>
      <H5
        lh={18}
        fz={14}
        weight={700}
        mb={4}
        sx={(t: Theme) => ({color: t.palette.grey[800]})}
      >{header ?? t('ERROR')}</H5>
      <Text
        sx={(t: Theme) => ({color: t.palette.grey[800]})}
        lh={18}
        fz={14}
        weight={500}
        dangerouslySetInnerHTML={{__html: text || ''}}
      />
    </>
  );
};

export const errorToast = (data: ToastDataProps, options?: ToastOptions) => {
  toast.error(() => <ErrorToastContent {...data} />, {
    type: toast.TYPE.DEFAULT,
    className: customErrorStyles,
    icon: <IconError />,
    closeButton: <IconCloseBtn />,
    toastId: data.id ?? data.text,
    ...options,
  });
  toast.update(data.id ?? data.text, options);
};

const WarnToastContent: FC<ToastContentProps> = ({header, text}) => {
  const {t} = useTranslate('common');
  return (
    <>
      <H5
        lh={20}
        fz={14}
        weight={700}
        mb={4}>{header ?? t('TOAST_WARNING_HEADER')}</H5>
      <Text
        sx={(t: Theme) => ({color: t.palette.grey[800]})}
        lh={18}
        fz={14}
        weight={500}>{text}</Text>
    </>
  );
};

export const warningToast = (data: ToastContentProps) => {
  toast.warn(() => <WarnToastContent {...data} />, {
    type: toast.TYPE.DEFAULT,
    className: customWarnStyles,
    icon: <IconWarn />,
    closeButton: <IconCloseBtn />,
  });
};

const SuccessToastContent: FC<ToastContentProps> = ({header, text}) => {
  const {t} = useTranslate('common');
  return (
    <>
      <H5
        sx={(t: Theme) => ({color: t.palette.grey[800]})}
        lh={18}
        fz={14}
        weight={700}>{header ?? t('SUCCESS_TOAST_HEADER')}</H5>
      <Text
        sx={(t: Theme) => ({color: t.palette.grey[800]})}
        lh={18}
        fz={14}
        weight={500}>{text}</Text>
    </>
  );
};

export const successToast = (data: ToastContentProps) => {
  toast.success(() => <SuccessToastContent {...data} />, {
    type: toast.TYPE.DEFAULT,
    className: customSuccessStyles,
    icon: <IconSVGSuccess />,
    closeButton: <IconCloseBtn />,
    autoClose: 10000,
    position: data.position,
  });
};
