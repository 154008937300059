import {apiClient} from '@/client/ApiClient';
import {ConnectionResponse} from './models';

export const createConnection = async () => {
  const response = await apiClient.post<ConnectionResponse>('/api/notifications/connect');
  return response.data;
};
export const closeConnection = async (connectionId: string) => {
  const response = await apiClient.post(`/api/notifications/${connectionId}/disconnect`);
  return response.data;
};

export * as notificationsApi from './';
export * from './models';
