import {SxProps, Theme, TooltipProps, Typography} from '@mui/material';
import {Tooltip} from '@/components/Tooltip';
import {useTranslate} from '@/i18n/useTranslate';
import {alpha} from '@/theme/utils';
import {FC, ForwardedRef, useEffect, useState} from 'react';

import {StyledInputTip} from './styles';

const isEllipsisActive = (e: HTMLElement | null) => {
  if (!e) {
    return false;
  }
  const tolerance = 2;
  return e.offsetWidth + tolerance < e.scrollWidth;
};
export const InputTip: FC<{
  color: 'success' | 'error' | 'default'
  disabled?: boolean
  children: string | undefined | null | boolean
  hide: boolean | undefined
  className?: string
  sx?: SxProps<Theme>
  errorTextStyles?: SxProps<Theme>
  errorTextPositionRelative?: boolean
  errorRef?: ForwardedRef<HTMLElement>
}> = ({
  sx,
  hide,
  color,
  children,
  className,
  errorTextStyles,
  errorTextPositionRelative,
  errorRef,
}) => {
  const [localRef, setLocalRef] = useState<HTMLElement | null>(null);
  const [withTooltip, setWithTooltip] = useState(false);

  const {t} = useTranslate('common');

  const combinedTypographySx: SxProps<Theme> = {
    color: color === 'error' ? 'error.main' : 'unset',
    ...(color === 'error' && errorTextStyles ? errorTextStyles : {}),
  };

  useEffect(() => {
    const currentRef = errorRef && 'current' in errorRef ? errorRef.current : localRef;
    const hasTooltip = Boolean(currentRef && isEllipsisActive(currentRef));
    setWithTooltip(hasTooltip);
  }, [children, errorRef, localRef]);

  if (hide) {
    return null;
  }

  const tip = (
    <StyledInputTip
      sx={[
        {cursor: withTooltip && color === 'error' ? 'pointer' : ''},
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      className={className}
      isRelativePosition={errorTextPositionRelative}
    >
      <Typography

        ref={(r) => {
          if (typeof errorRef === 'function') {
            errorRef(r);
          } else if (errorRef && 'current' in errorRef) {
            errorRef.current = r;
          }
          setLocalRef(r as HTMLElement | null);
        }}
        variant='12_16_500'
        sx={combinedTypographySx}
      >
        {typeof children === 'string' ? children : null}
      </Typography>
    </StyledInputTip>
  );

  if (withTooltip && color === 'error') {
    return (
      <AdjustedTooltip
        title={
          <>
            <strong>{t('ERROR')}:</strong>&nbsp; {children}
          </>
        }
      >
        {tip}
      </AdjustedTooltip>
    );
  }

  return tip;
};

const AdjustedTooltip: FC<TooltipProps> = ({children, sx, ...restProps}) => {
  return (
    <Tooltip
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      placement={'bottom-end'}
      sx={[
        (t) => ({
          '.MuiTooltip-arrow': {
            right: 0,
            left: 'initial !important',
            transform: 'translateX(-18px) !important',
            fontSize: '12px',
            lineHeight: '18px',
            color: alpha(t.palette.error.main, 20),
          },
          '.MuiTooltip-tooltip': {
            backgroundColor: alpha(t.palette.error.main, 20),
            color: t.palette.grey[700],
            paddingX: 10,
            paddingY: 6,
            maxWidth: 260,
            marginTop: '10px !important',
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
};
