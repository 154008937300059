import {put} from 'redux-saga/effects';
import {getError} from './common/error-handlers';
import {notifyActions} from './notifications/slice';
import {Statuses} from '@/client/types';
import {ValidationErrorType} from '@/types';

export function * errorHandler (e: any, errorAction?: (error: ValidationErrorType) => any) {
  const error = getError(e);
  if (typeof errorAction === 'function') {
    yield put(errorAction(error));
  };
  if (e.status !== Statuses.UNAUTHORIZED) {
    yield put(notifyActions.showErrors(error));
  };
}
