import {ValidationErrorType} from '@/types';
import {errorToast, successToast} from '@/components/Toast/Toast';
import {toast} from 'react-toastify';
import {all, call, put, select, takeEvery} from 'redux-saga/effects';

import {SagaPayload, SagaReturn} from '../types';

import {RootState} from './../store';
import {notifyActions, NotificationsState, Notification} from './slice';

export function * notificationsSaga () {
  yield all([
    takeEvery(notifyActions.dismissNotifications, dismissNotifications),
    takeEvery(notifyActions.showNotifications, showNotifications),
    takeEvery(notifyActions.showErrors, showErrors),
  ]);
}

function * showErrors ({payload}: SagaPayload<typeof notifyActions.showErrors>) {
  const errors: Notification[] = [];
  if (typeof payload === 'string') {
    errors.push({text: payload, id: payload, type: 'error'});
  } else {
    for (const p of payload) {
      errors.push({text: p, id: p, type: 'error'});
    }
  }
  yield put(notifyActions.showNotifications(errors));
}

function * showNotifications ({payload}: SagaPayload<typeof notifyActions.showNotifications>) {
  for (const n of payload) {
    if (n.type === 'error') {
      errorToast({text: n.text as ValidationErrorType}, {autoClose: 10000});
    }
    if (n.type === 'success') {
      successToast({text: n.text, position: 'top-right'});
    }
  }
}

function * dismissNotifications (): SagaReturn {
  const snapshot1: NotificationsState = yield select((state: RootState) => state.notifications);
  const newCloseQueue = [...snapshot1.notifications?.allIDs ?? []];
  yield put(notifyActions._setDismissalQueue(newCloseQueue));
  yield call(delay(600));
  const snapshot2: NotificationsState = yield select((state: RootState) => state.notifications);
  for (const id of snapshot2.dismissalQueue ?? []) {
    toast.dismiss(id);
  }
  yield put(notifyActions._removeNotifications(snapshot2.dismissalQueue ?? []));
  yield put(notifyActions._setDismissalQueue(null));
}

const delay = (num: number) => () => new Promise((resolve) => window.setTimeout(resolve, num));
