import {Stack, Typography, Box} from '@mui/material';
import {ControlContainer} from '@/components/form/InputControl/styles';
import cn from 'classnames';
import {useField} from 'formik';
import {FC, forwardRef, useId} from 'react';

import {US_DATE_PLACEHOLDER_SLASHED} from '../../DateFormat';
import {InputTip} from '../InputControl';
import {CONTROL_CONTAINER} from '../InputControl/InputControl';
import {LabelOptional} from '../SelectControl/LabelOptional';

import {DateInput} from './DateInput';
import {getColor, getInputValue, getPlaceholder} from './helpers.';
import {IInputProps} from './types';

export const DateControl = forwardRef<HTMLElement, IInputProps>(
  (
    {
      disabledFuture,
      disabledPast,
      onlyFutureYears,
      hideTip,
      disabled,
      success,
      tip,
      placeholder = US_DATE_PLACEHOLDER_SLASHED,
      range,
      name,
      value,
      onChange,
      error,
      label,
      type,
      className,
      optional,
      sx,
      isIconVisible,
      onTouched,
      reverseYears,
      labelTop,
      labelLeft,
      isLabelOutside,
      startRangeWithCurrentMonth,
      minWidth,
      maxWidth,
      errorTextStyles,
      disableCurrentDay,
      errorTextPositionRelative,
      dataTestId = 'date-control',
    },
    ref,
  ) => {
    const isDisabled = typeof disabled === 'function' ? disabled() : disabled;

    const id = useId();

    return (
      <ControlContainer
        className={cn(className, CONTROL_CONTAINER)}
        sx={sx}
        disabled={isDisabled}
        onClick={() => (onTouched && name ? onTouched(name, true) : null)}
        data-test-id={dataTestId}
      >
        <Box display="flex" gap={10}>
          {labelTop && (
            <Typography mb={2} variant="14_18_700">
              {label}
            </Typography>
          )}
          {optional && (
            <LabelOptional
              isLabelOutside={isLabelOutside}
              label={label}
              optional={optional}
              disabled={!!isDisabled}
            />
          )}
        </Box>
        <Stack flexDirection="row" alignItems="center" gap={12}>
          {labelLeft && (
            <Typography variant="14_18_700" whiteSpace={'nowrap'}>
              {label}
            </Typography>
          )}
          <DateInput
            minWidth={minWidth}
            maxWidth={maxWidth}
            startRangeWithCurrentMonth={startRangeWithCurrentMonth}
            reverseYears={reverseYears}
            disabledFuture={disabledFuture}
            disabledPast={disabledPast}
            onlyFutureYears={onlyFutureYears}
            disabled={isDisabled}
            name={name ?? ''}
            error={!!error}
            label={!labelLeft && !labelTop ? label : ''}
            success={!!success}
            id={id}
            value={getInputValue({type, range, value})}
            onChange={onChange}
            placeholder={getPlaceholder({placeholder, type})}
            type={type}
            range={range}
            isIconVisible={isIconVisible}
            disableCurrentDay={disableCurrentDay}
          />
        </Stack>
        <InputTip
          errorRef={ref}
          errorTextPositionRelative={errorTextPositionRelative}
          errorTextStyles={errorTextStyles}
          hide={hideTip}
          disabled={isDisabled}
          color={getColor({error, success})}
        >
          {error || success || tip}
        </InputTip>
      </ControlContainer>
    );
  },
);

DateControl.displayName = 'DateControl';

export const DateControlF: FC<Omit<IInputProps, 'name'> & { name: string }> = (props) => {
  const {label: _, ...restProps} = props;
  const [field, meta] = useField(restProps as any);
  return <DateControl {...field} {...props} error={meta.touched && meta.error} />;
};
