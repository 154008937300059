/** @jsxImportSource @emotion/react */
import {css, GlobalStyles} from '@mui/material';
import {ToastContainer as ToastifyToastContanier} from 'react-toastify';

const toastifyOverrideStyles = css`
    .Toastify {
        &__toast {
            border-radius: 0;
            padding: 12px;
            font-family: inherit;
            min-height: 76px;
        }
        &__toast-container {
            width: 308px;
            top: 70px;
            right: 68px;

            @media (min-width: 600px) and (max-width: 1199px) {
                top: 74px;
                right: 56px;
            }

            @media (min-width: 0px) and (max-width: 599px) {
                top: 45px;
                right: 34px;
                left: auto;
                padding: 6px;
                width: 342px;
            }
        }
        &__toast-body {
            align-items: start;
            padding: 0;
            margin-top: 0;
            margin-bottom: 8px;

            & > div:last-child {
                margin-top: 3px;
            }
        };
        &__toast-icon {
            width: 24px;
            margin-inline-end: 8px;
        }
        .mobile-layout & {
            &__toast {
                margin: 16px;
                margin-top: 24px;
            }
        }
    }
`;
export const ToastContainer = () => {
  return (
    <>
      <GlobalStyles styles={toastifyOverrideStyles} />
      <ToastifyToastContanier
      />
    </>
  );
};
