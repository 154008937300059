import {ConnectionResponse} from '@/api';
import {Client} from '@stomp/stompjs';
import {eventChannel} from 'redux-saga';
import {messengerActions} from './slice';
import {getError} from '../common/error-handlers';

export const connectClient = (connection: ConnectionResponse) => {
  return eventChannel((emitter: ({type}: {type: string}) => void) => {
    const {wssUrl, user, password, virtualHost, queue, expired, connectionId} = connection;

    const client: Client = new Client({
      brokerURL: wssUrl,
      connectHeaders: {
        login: user,
        passcode: password,
        host: virtualHost,
      },
    });

    const connect = () => {
      client.subscribe(
        `/queue/${queue}`,
        () => {
          emitter(messengerActions.requestMessengerStatistic());
        },
        {'x-expires': expired},
      );
    };
    client.onConnect = () => connect();
    client.activate();
    client.onStompError = (frame) => {
      const error = getError(frame.body);
      console.warn(error);
    };
    return () => emitter(messengerActions.requestConnectionClose({id: connectionId})); ;
  },
  );
};
