import {accountApi, identityApi} from '@/api';
import {all, takeLeading, call, SagaReturnType, put} from 'redux-saga/effects';
import {accountActions as actions} from './slice';
import {SagaPayload, SagaReturn} from '../types';
import {globalStateResetAction} from '../common/actions';
import {sortAccountProfileData} from '@/shared/utils';
import {relationsActions} from '../relations/slice';
import {envs} from '@/shared/constants/envs';
import {errorHandler} from '../errorHandler';
export function * accountSaga () {
  yield all([
    takeLeading(actions.requestAccount, requestAccount),
    takeLeading(actions.requestDashboardData, requestDashboardData),
    takeLeading(actions.requestAccountProfileData, requestAccountProfileData),
    takeLeading(actions.doLogout, doLogout),
    takeLeading(actions.updateAccountProfile, updateAccountProfile),
  ]);
}

export function * doLogout () {
  try {
    yield call(identityApi.logout);
    yield put(globalStateResetAction());
    const url = envs.PASSPORT_WEB_URL;
    if (url) {
      window.location.assign(url);
    } else {
      console.error('[saga doLogout] PASSPORT_WEB_URL is not defined');
    }
  } catch (e) {
    errorHandler(e);
  }
}

export function * requestAccount (): SagaReturnType<any> {
  try {
    yield put(actions.setFetching({account: true}));
    const account: Awaited<ReturnType<typeof accountApi.getAccount>> = yield call(accountApi.getAccount);
    yield put(actions.setAccountData({account: account?.data}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setFetching({account: false}));
  }
}

export function * requestDashboardData ({payload}: SagaPayload<typeof actions.requestDashboardData>) {
  try {
    yield put(actions.setFetching({dashboard: true}));
    const dashboard: Awaited<ReturnType<typeof accountApi.getDashboard>> = yield call(accountApi.getDashboard, {dashboardInterval: payload.dashboardInterval});
    yield put(actions.setDashboardData({dashboard, interval: payload.dashboardInterval}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setFetching({dashboard: false}));
  }
}

export function * requestAccountProfileData (): SagaReturnType<any> {
  try {
    yield put(actions.setFetching({profile: true}));
    const data: Awaited<ReturnType<typeof accountApi.getAccountProfile>> = yield call(accountApi.getAccountProfile);
    const accountProfile: Awaited<ReturnType<typeof accountApi.getAccountProfile>> = sortAccountProfileData(data);
    yield put(actions.setAccountProfileData({accountProfile}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setFetching({profile: false}));
  }
};

function * updateAccountProfile ({
  payload,
}: SagaPayload<typeof actions.updateAccountProfile>): SagaReturn {
  try {
    yield call(accountApi.updateAccountProfile, {
      data: payload.data,
    });
    const data: Awaited<ReturnType<typeof accountApi.getAccountProfile>> = yield call(accountApi.getAccountProfile);
    const accountProfile: Awaited<ReturnType<typeof accountApi.getAccountProfile>> = sortAccountProfileData(data);
    yield put(actions.setAccountProfileData({accountProfile}));
    yield put(actions.requestAccount());
    yield put(relationsActions.setStep({step: 'init'}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  }
}
