import {Stack, Typography, Box, GlobalStyles} from '@mui/material';
import {
  BurgerLogoContainer,
  Content,
  globalStyles,
  GridContainer,
  Header,
  Main,
  PageBody,
  StyledAppLogo,
} from '@/features/layouts/MainLayout/styles';
import {HEADER_DATA_TEST_ID} from '@/features/layouts/MainLayout/testIds';
import {useTranslate} from '@/i18n/useTranslate';
import {ROUTERS_PATH} from '@/routers';
import Image404 from '@/shared/assets/icons/404.svg?react';
import {useMQuery} from '@/shared/hooks';
import {testId} from '@/shared/utils/testId';
import {FC} from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'ui-kit';

import {sx} from './styles';

interface NotFoundProps {
  isSeparate?: boolean;
}

const NotFound: FC<NotFoundProps> = ({isSeparate = false}) => {
  const {mobile, tablet, desktop} = useMQuery();
  const {t, ready} = useTranslate('common');
  const textTablet = tablet ? '18_24_500' : '14_18_500';
  const textVariant = desktop ? '22_26_500' : textTablet;

  const mainComponent = (
    <Stack sx={sx.container}>
      <Stack sx={sx.body}>
        <Stack sx={sx.whiteBox}>
          <Stack sx={sx.content} gap={48}>
            <Stack sx={sx.imageContainer}>
              <Box component={Image404} height={'100%'} />
            </Stack>
            {ready && (
              <Stack gap={12} sx={sx.textContent}>
                <Typography variant={mobile ? '24_34_700' : '36_38_700'}>
                  {t('PAGE_NOT_FOUND')}
                </Typography>
                <Typography variant={textVariant}>{t('SORRY_THE_PAGE')}</Typography>
                <Stack direction={{xs: 'column', md: 'row'}} sx={sx.lineWithButton}>
                  <Typography variant={textVariant}>{t('TRY_TO_RETURN_TO')}</Typography>
                  <Button
                    size={'sm'}
                    component={Link}
                    to={{
                      pathname: ROUTERS_PATH.OVERVIEW,
                      search: window.location.search,
                    }}
                  >
                    {t('VIVICA_PATIENT')}
                  </Button>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );

  if (isSeparate) {
    return (
      <>
        <GlobalStyles styles={globalStyles} />
        <PageBody>
          <Header>
            <BurgerLogoContainer>
              <Link
                style={{lineHeight: 0}}
                to={{pathname: ROUTERS_PATH.OVERVIEW, search: window.location.search}}
                data-test-id={testId(HEADER_DATA_TEST_ID.headerLogoButton)}
              >
                <StyledAppLogo sx={{mb: {xs: 6, sm: 0}}} />
              </Link>
            </BurgerLogoContainer>
          </Header>
          <Main>
            <GridContainer id="main">
              <Content>{mainComponent}</Content>
            </GridContainer>
          </Main>
        </PageBody>
      </>
    );
  }

  return mainComponent;
};

export default NotFound;
