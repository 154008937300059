import {globalNotificationsApi} from '@/api/globalNotifications';
import i18n from '@/i18n/i18n';

import {getError} from '../common/error-handlers';
import {errorHandler} from '../errorHandler';
import {notifyActions} from '../notifications/slice';
import {envs} from '@/shared/constants/envs';
import {createSlice, initialStateData} from './constants';
import {FetchGlobalNotificationsProps, UpdateGlobalNotificationStatusProps} from './types';

export const globalNotifications = createSlice({
  name: 'globalNotifications',
  initialState: initialStateData,
  reducers: (create) => ({
    fetchGlobalNotifications: create.asyncThunk(
      async (
        {perPage, isCheckingUnread, ...payload}: FetchGlobalNotificationsProps,
        {rejectWithValue, dispatch},
      ) => {
        try {
          const data = await globalNotificationsApi.getGlobalNotifications({perPage, ...payload});
          const isNext = data.length === perPage;
          return {data, isNext, isCheckingUnread};
        } catch (e) {
          const error = getError(e);
          errorHandler(error);
          dispatch(
            notifyActions.showNotifications([{id: '', type: 'error', text: i18n.t('SMTH_WENT_WRONG', {link: envs.PATIENT_CONTACT_SUPPORT_URL})}]),
          );
          return rejectWithValue(error);
        }
      },
      {
        pending: (state) => {
          state.isLoading = true;
          state.isError = false;
        },
        fulfilled: (state, action) => {
          state.isLoading = false;
          state.isError = false;
          const {data, isNext, isCheckingUnread} = action.payload;

          if (isCheckingUnread) {
            state.hasUnreadNotification = data.some((notification) => !notification.read);
          } else {
            state.notifications = [...state.notifications, ...data];
            state.isNext = isNext;
          }
        },
        rejected: (state, action) => {
          state.isLoading = false;

          if (!action.meta.arg.isCheckingUnread) {
            state.isError = true;
          }
        },
      },
    ),
    updateGlobalNotificationStatus: create.asyncThunk(
      async (
        payload: UpdateGlobalNotificationStatusProps,
        {rejectWithValue, dispatch},
      ) => {
        try {
          const data = await globalNotificationsApi.updateGlobalNotificationStatus(payload);
          return {data};
        } catch (e) {
          const error = getError(e);
          errorHandler(error);
          dispatch(
            notifyActions.showNotifications([{id: '', type: 'error', text: i18n.t('SMTH_WENT_WRONG', {link: envs.PATIENT_CONTACT_SUPPORT_URL})}]),
          );
          return rejectWithValue(error);
        }
      }, {
        pending: (state) => {
          state.isLoading = true;
          state.isError = false;
        },
        fulfilled: (state, {meta}) => {
          state.isLoading = false;
          state.isError = false;

          const updatedNotificationIds = meta.arg.requests;

          const updatedNotifications = state.notifications.map((notification) => {
            if (updatedNotificationIds.includes(notification.id)) {
              return {
                ...notification,
                read: true,
              };
            }
            return notification;
          });

          state.notifications = updatedNotifications;
        },
        rejected: (state) => {
          state.isLoading = false;
          state.isError = true;
        },
      },
    ),
    resetErrorState: create.reducer((state) => {
      state.isError = false;
    }),
  }),
});

export const globalNotificationsReducer = globalNotifications.reducer;
export const globalNotificationsActions = globalNotifications.actions;
export const globalNotificationsStoreName = globalNotifications.name;
