/** @jsxImportSource @emotion/react */
import {Box, Typography} from '@mui/material';
import {useTranslate} from '@/i18n/useTranslate';
import React from 'react';

export const LabelOptional = ({
  optional,
  className,
  disabled,
  isLabelOutside,
  label,
}: {
  optional?: boolean
  className?: string
  disabled: boolean
  isLabelOutside?: boolean
  label?: string
}) => {
  const {t} = useTranslate('common');
  return (
    <Box height={18} display={'flex'} className={className}>
      {isLabelOutside && <Typography variant={'14_18_700'}>{label}</Typography>}
      {optional && (
        <Typography
          fontSize={'12px'}
          lineHeight={'18px'}
          fontWeight={400}
          fontStyle={'italic'}
          color={disabled ? 'grey.300' : 'grey.500'}
        >
          {`(${t('OPTIONAL')})`.toLowerCase()}
        </Typography>
      )}
    </Box>
  );
};
