import {useAppDispatch, useAppSelector} from '@/store';
import {useCallback, useEffect, useState} from 'react';
import {messengerActions} from './slice';

const REQUEST_BLOCK_TIMEOUT = 5000;

const useMessengerState = () => useAppSelector((state) => state.messenger);

export const useMessengerStatistic = () => {
  const messengerStatisticState = useMessengerState();
  const dispatch = useAppDispatch();

  const [isCountRequestBlocked, setIsCountRequestBlocked] = useState(false);

  const requestMessagesCount = useCallback(() => {
    if (isCountRequestBlocked) {
      return;
    }

    dispatch(messengerActions.requestMessengerStatistic());
    setIsCountRequestBlocked(true);
    setTimeout(() => setIsCountRequestBlocked(false), REQUEST_BLOCK_TIMEOUT);
  }, [dispatch, isCountRequestBlocked]);

  useEffect(() => {
    if (messengerStatisticState.unreadCount === null) {
      requestMessagesCount();
    }

    document.addEventListener('click', requestMessagesCount);
    return () => document.removeEventListener('click', requestMessagesCount);
  }, [messengerStatisticState.unreadCount, requestMessagesCount]);

  useEffect(() => {
    dispatch(messengerActions.requestConnectionConfigure());
  }, [dispatch]);

  return {
    unreadCount: messengerStatisticState.unreadCount,
  };
};
