import {envs} from '@/shared/constants/envs';
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';

import {Statuses} from './types';
const unauthorizedInterceptor = [(response: AxiosResponse<any, any>) => {
  return response;
}, (e: AxiosError<any>): any => {
  if (e.response?.status === Statuses.UNAUTHORIZED) {
    const url = envs.PASSPORT_WEB_URL;
    if (!url) {
      console.error('[unauthorizedInterceptor] PASSPORT_WEB_URL is not defined');
      return Promise.reject(e.response);
    }
    const currentLocation = window.location.href;
    window.location.href = url + '/auth/welcome?backUrl=' + currentLocation;
    return Promise.reject(e.response);
  }
  return Promise.reject(e.response);
}];
const allInterceptors = [
  unauthorizedInterceptor,
];
const getApiClient = (options: AxiosRequestConfig, interceptors: any[] = []) => {
  const apiClient = axios.create(options);
  for (const interceptor of interceptors) {
    apiClient.interceptors.response.use(...interceptor);
  }
  return apiClient;
};

export const apiClient = getApiClient({
  withCredentials: true,
  baseURL: envs.BASE_API,
}, allInterceptors);

export const identityApiClient = getApiClient({
  withCredentials: true,
  baseURL: envs.PASSPORT_IDENTITY_URL,
}, allInterceptors);
