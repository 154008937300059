/** @jsxImportSource @emotion/react */
import {css as _, IconButton, Stack, styled} from '@mui/material';
import {IconContainer} from '@/components/IconContainer';
import {MAX_YEAR, MIN_YEAR} from '@/components/form/DateControl/DateInput/constants';
import IconSVGArrowLeft from '@/shared/assets/images/icons/icon_arrow_left.svg?react';
import {spreadSx} from '@/shared/utils/spreadSx';
import {DateTime} from 'luxon';
import {FC} from 'react';
import {Button} from 'ui-kit';

import {TSelectorType} from '../types';

const buttonSxStyles = {
  paddingLeft: 5,
  paddingRight: 0,
  minWidth: 'initial',
  width: 'auto',
  '&:disabled': {color: 'white!important'},
};
export type THeaderActionType = 'back' | 'forward' | 'month' | 'year' | 'day';
interface IYMSelectorProps {
  date: DateTime
  className?: string
  onClick?: (e: { type: THeaderActionType }) => void
  type?: TSelectorType
  isRangeMode?: boolean
  disabledFuture?: boolean
}

export const Header: FC<IYMSelectorProps> = ({
  type,
  date,
  className,
  onClick,
  isRangeMode,
  disabledFuture,
}) => {
  const isNextNavigationDisabled = disabledFuture
    ? date.year === DateTime.now().year && date.month === DateTime.now().month
    : date.year === MAX_YEAR && date.monthLong === 'December';

  const isPrevNavigationDisabled = date.year === MIN_YEAR && date.monthLong === 'January';

  return (
    <SelectorContainer className={className}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        flexGrow={1}
        minWidth={isRangeMode ? 0 : 120}>
        <Button
          variant={'text'}
          color={'secondary'}
          sx={buttonSxStyles}
          disabled={type === 'month'}
          onClick={() => {
            onClick?.({type: 'month'});
          }}
        >
          {date.monthLong}
        </Button>
        <Button
          variant={'text'}
          color={'secondary'}
          sx={[...spreadSx(buttonSxStyles), {flexGrow: 1}]}
          onClick={() => {
            onClick?.({type: 'day'});
          }}
          disabled={type === 'day'}
        >
          {date.day}
        </Button>
      </Stack>
      <Stack direction={'row'} css={_`align-items: center; display: flex;`}>
        <Button
          variant={'text'}
          color={'secondary'}
          sx={buttonSxStyles}
          onClick={() => {
            onClick?.({type: 'year'});
          }}
          disabled={type === 'year'}
        >
          {date.year}
        </Button>
      </Stack>
      <Stack
        direction={'row'}
        css={(t) => _`svg path {stroke: ${t.palette.secondary.main}}`}
      >
        <NavigationButton
          onClick={() => {
            onClick?.({type: 'back'});
          }}
          disabled={isPrevNavigationDisabled}
        >
          <IconContainer>
            <IconSVGArrowLeft css={_`path {stroke: white}`} />
          </IconContainer>
        </NavigationButton>
        <NavigationButton
          disabled={isNextNavigationDisabled}
          onClick={() => {
            onClick?.({type: 'forward'});
          }}
        >
          <IconContainer>
            <IconSVGArrowLeft
              css={_`transform: rotate(180deg); path {stroke: white}`}
            />
          </IconContainer>
        </NavigationButton>
      </Stack>
    </SelectorContainer>
  );
};

const NavigationButton = styled(IconButton)(
  ({theme: t}) => _`
  &:hover path {
    stroke: ${t.palette.secondary.light};
  };
  &:active path {
    stroke: ${t.palette.secondary.dark};
  }
`,
);

const SelectorContainer = styled('div')(
  ({theme: t}) => _`
  padding: 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  height: 52px;
  background-color: ${t.palette.grey[800]};
`,
);
