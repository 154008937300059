import {
  GetResultParams,
  GetResultsParams,
  SendRequest,
  SendResultParams,
} from '@/api/__generated__/webApi';
import {getRecaptchaToken} from '@/shared/hooks/useRecaptcha';
import {apiClient} from '@/client/ApiClient';

import {
  Result,
  DynamicsTest,
  ResultsSearch,
  DynamicResultType,
  ManualResultDetail,
  LaboratoryResultDetail,
} from './models';
export * from './models';

/**
 * @description Getting account results
 *
 * @tags Results
 * @name GetResults
 * @summary Results
 * @request GET:/api/results
 * @docs https://patient-web.test.private.lifedl.net/swagger-ui/index.html#Results/getResults
 * @response `200` `(BaseResult)[]` OK
 * @response `401` `undefined` Unauthorized
 * @response `500` `BaseError` Internal Server Error
 */
export const getResults = async (query: GetResultsParams) => {
  const response = await apiClient.get<Result[]>('/api/results', {
    params: query,
  });
  return response.data;
};

/**
 * @description Getting account result
 *
 * @tags Results
 * @name GetResult
 * @summary Result
 * @request GET:/api/results/{id}
 * @docs https://patient-web.test.private.lifedl.net/swagger-ui/index.html#Results/getResult
 * @response `200` `BaseResultDetail` OK
 * @response `401` `undefined` Unauthorized
 * @response `403` `undefined` Forbidden
 * @response `404` `undefined` Not Found
 * @response `500` `BaseError` Internal Server Error
 */
export const getResultByid = async ({id, ...query}: GetResultParams) => {
  const response = await apiClient.get<ManualResultDetail | LaboratoryResultDetail>(
    `/api/results/${id}`,
    {
      params: query,
    },
  );
  return response.data;
};

/**
 * @description Send result
 *
 * @tags Results
 * @name SendResult
 * @summary Send result
 * @request POST:/api/results/{id}/fn/send
 * @docs https://patient-web.test.private.lifedl.net/swagger-ui/index.html#Results/sendResult
 * @response `200` `undefined` OK
 * @response `400` `ValidationError` Bad request
 * @response `401` `undefined` Unauthorized
 * @response `403` `undefined` Forbidden
 * @response `500` `BaseError` Internal Server Error
 */
export const sendResultsByEmail = async ({
  params: {id, ...query},
  data,
}: {
  params: SendResultParams
  data: SendRequest
}) => {
  void (await apiClient.post(`/api/results/${id}/fn/send`, data, {params: query}));
};

export const getDynamics = async (): Promise<DynamicsTest[]> => {
  const response = await apiClient.post<DynamicsTest[]>('/api/dynamics/search', {
    type: DynamicResultType.All,
  });
  return response.data;
};

export const searchResults = async ({
  key,
  lastName,
}: {
  key: string
  lastName?: string | null
}) => {
  const recaptcha = await getRecaptchaToken();
  const response = await apiClient.post<ResultsSearch>('/api/anonymous/results/search', {
    key,
    lastName,
    recaptcha,
  });
  return response;
};

export * as resultsApi from './';
