import {SxProps, Theme} from '@mui/material';
import IconSVGTimes from '@/shared/assets/images/icons/icon_times.svg?react';
import {testId} from '@/shared/utils/testId';
import {memo, PropsWithChildren} from 'react';

import {StyledDialog} from './StyledDialog';
import {StyledDialogActions} from './StyledDialogActions';
import {StyledDialogTitle, StyledButton, StyledDialogContent} from './styles';
// TODO make it not to affect on scrollbar of the app

export const Dialog = StyledDialog;

interface IDialogTitleProps {
  onClose?: () => void
  className?: string
  noContent?: boolean
  sx?: SxProps<Theme>
  width?: string
  closeButtonDataTestId?: string
}
export const DialogTitle = memo(
  ({
    onClose,
    children,
    className,
    noContent,
    sx,
    width,
    closeButtonDataTestId,
  }: PropsWithChildren<IDialogTitleProps>) => {
    return (
      <StyledDialogTitle className={className} sx={sx}>
        {children}
        {!noContent && (
          <StyledButton
            width={width}
            type="button"
            onClick={onClose}
            data-test-id={testId(closeButtonDataTestId || '')}
          >
            <IconSVGTimes />
          </StyledButton>
        )}
      </StyledDialogTitle>
    );
  },
);
DialogTitle.displayName = 'DialogTitle';
export const DialogContent = StyledDialogContent;
export const DialogActions = StyledDialogActions;
