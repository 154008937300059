import {Theme, SxProps} from '@mui/material';
import {paletteColors} from '@/theme';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: {
      xs: '70px',
      sm: '200px',
      lg: '45px',
    },
  },
  comingSoonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '30px',
    position: 'relative',
    bottom: '-55px',
    left: '-50px',
  },
  mainText: (t) => ({
    color: paletteColors.grey[800],
    fontWeight: 800,
    fontSize: '95px',
    lineHeight: '95px',
    [t.breakpoints.down('lg')]: {
      fontSize: '65px',
      lineHeight: '68px',
    },
  }),
  codeSignImage: (t) => ({
    width: '78px',
    height: '89px',
    position: 'absolute',
    top: '-30px',
    right: '-100px',
    [t.breakpoints.down('lg')]: {
      top: '-20px',
    },
  }),
  programmerImage: (t) => ({
    width: '890px',
    height: '496px',
    [t.breakpoints.down('lg')]: {
      width: '595px',
      height: '354px',
    },
  }),
};
