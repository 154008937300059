import {ComingSoon} from '@/components/ComingSoon/ComingSoon';

const Tasks = () => {
  return (
    <>
      <ComingSoon />
    </>
  );
};

export default Tasks;
