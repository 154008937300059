import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FavoriteProfessional} from '@/api/favorites';
import {PartialRecord, ValidationErrorType} from '@/types';

type Fetching = 'common' | 'account' | 'dashboard' | 'profile' | 'favorites';
export interface FavoritesState {
  doctors: FavoriteProfessional[] | null
  fetching: PartialRecord<Fetching, boolean> | null
  error?: ValidationErrorType | null
}
const initialState: FavoritesState = {
  doctors: null,
  fetching: null,
};

export const favorites = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    requestDoctors () {},
    setDoctors (state, {payload}: PayloadAction<{doctors: FavoriteProfessional[]}>) {
      state.doctors = payload.doctors;
    },
    deleteFavoriteDoctorRequest (_state, _payload: PayloadAction<{doctorId: string}>) {},
    setFetching (state, {payload}: PayloadAction<FavoritesState['fetching']>) {
      if (!payload) {
        state.fetching = null;
      }
      state.fetching = {...state.fetching, ...payload};
    },
    setCommonError (state, {payload}: PayloadAction<ValidationErrorType | null>) {
      state.error = payload;
    },
  },
});

export const favoritesReducer = favorites.reducer;
export const favoritesActions = favorites.actions;
export const favoritesStoreName = favorites.name;
