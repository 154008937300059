import {createAsyncThunk, createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DynamicsTest, LaboratoryResultDetail, ManualResultDetail, Result, storageApi} from '@/api';
import {
  ResultType,
  StorageFileCategory,
  StorageFileCreateRequest,
} from '@/api/__generated__/webApi';
import {RootState} from '@/store';
import {deleteByIdFromNormalizedState} from '@/store/common/normalized';
import {UUID, ValidationErrorType} from '@/types';

import {globalStateResetAction} from '../common/actions';
import {createNormalizedState, updateNormalizedState} from '../common/normalized';

import {DynamicTestTransformed} from './helpers';
import {
  AddHealthCaseProps,
  ResultsFilters,
  IResultsState,
  TRequestNPIOrgFlowSteps,
  RemoveHealthCaseProps,
  AddManualResultProps,
} from './types';
import {initialFilters} from './constants.ts';
import {notifyActions} from '@/store/notifications/slice';
import {envs} from '@/shared/constants/envs';
import i18n from '@/i18n/i18n';
import {updateStorageFile} from '@/store/storage/slice';

const initialState: IResultsState = {
  results: null,
  filters: initialFilters,
  filteredResults: [],
  resultLaboratoryDetails: null,
  resultManualDetails: null,
  commonError: null,
  dynamics: null,
  fetching: null,
  testResults: [],
  step: 'init',
  errorStatus: null,
};

export const addManualResult = createAsyncThunk(
  'results/addManualResult',
  async ({result, file}: AddManualResultProps, {rejectWithValue, dispatch}) => {
    try {
      const {
        date,
        description,
        favorite: _favorite,
        name,
        category: _category,
        recognize,
        createBiomarkers,
        ...otherData
      } = result;

      const data: StorageFileCreateRequest = {
        date: {
          value: date,
        },
        description: {
          value: description,
        },
        favorite: {
          value: false,
        },
        name: name
          ? {
              value: name,
            }
          : undefined,
        recognize,
        createBiomarkers,
        category: StorageFileCategory.LABORATORY_REPORT,
        ...otherData,
      };

      const newResult = await storageApi.createStorageFile({data, file});

      dispatch(
        notifyActions.showNotifications([
          {id: '', type: 'success', text: i18n.t('RESULT_SAVED_SUCCESS')},
        ])
      );

      return newResult;
    } catch (e) {
      dispatch(
        notifyActions.showNotifications([
          {
            id: '',
            type: 'error',
            text: i18n.t('SMTH_WENT_WRONG', {link: envs.PATIENT_CONTACT_SUPPORT_URL}),
          },
        ])
      );
      return rejectWithValue(e);
    }
  }
);

export const slice = createSlice({
  name: 'results',
  initialState,
  reducers: {
    requestResults() {},
    requestResultDetails(state, _action: PayloadAction<{resultId: UUID; type: ResultType}>) {
      state.errorStatus = null;
    },
    requestDynamics() {},
    setResults(state, {payload}: PayloadAction<Result[]>) {
      if (!state.results) {
        state.results = createNormalizedState(payload);
      } else {
        updateNormalizedState(state.results, payload);
      }
    },
    setFilterResults: (state, {payload}: PayloadAction<Result[]>) => {
      state.filteredResults = payload;
    },
    setLaboratoryDetails(state, {payload}: PayloadAction<LaboratoryResultDetail>) {
      if (!state.resultLaboratoryDetails) {
        state.resultLaboratoryDetails = createNormalizedState([payload]);
      } else {
        updateNormalizedState(state.resultLaboratoryDetails, [payload]);
      }
    },
    setManualDetails(state, {payload}: PayloadAction<ManualResultDetail>) {
      if (!state.resultManualDetails) {
        state.resultManualDetails = createNormalizedState([payload]);
      } else {
        updateNormalizedState(state.resultManualDetails, [payload]);
      }
    },
    setDynamics(state, {payload}: PayloadAction<DynamicsTest[]>) {
      state.dynamics = payload;
    },
    setTranformedData(state, {payload}: PayloadAction<DynamicTestTransformed[]>) {
      state.testResults = payload;
    },
    sendResultsByEmail(
      _state,
      _payload: PayloadAction<{resultId: UUID; email: string; type: ResultType}>
    ) {},
    setFetching(state, {payload}: PayloadAction<IResultsState['fetching']>) {
      if (!payload) {
        state.fetching = null;
      }
      state.fetching = {...(state.fetching ?? {}), ...payload};
    },
    setStep(state, {payload}: PayloadAction<{step: TRequestNPIOrgFlowSteps}>) {
      state.step = payload.step;
    },
    setCommonError(state, {payload}: PayloadAction<ValidationErrorType | null>) {
      state.commonError = payload;
    },
    setErrorStatus(state, {payload}: PayloadAction<number | null>) {
      state.errorStatus = payload;
    },
    removeResult(state, {payload}: PayloadAction<Result['id']>) {
      if (state.results) {
        deleteByIdFromNormalizedState(state.results, [payload]);
      }
      state.testResults = [];
    },
    addHealthCase: (state, {payload}: PayloadAction<AddHealthCaseProps>) => {
      const result = state.results?.byID?.[payload.id];

      if (result?.id) {
        result.healthCases = [...(result.healthCases || []), payload.healthCase];
      }
    },
    removeHealthCase: (state, {payload}: PayloadAction<RemoveHealthCaseProps>) => {
      const result = state.results?.byID?.[payload.id];

      if (result?.id) {
        result.healthCases = result.healthCases?.filter((hc) => payload.healthCaseId !== hc.id);
      }
    },
    updateFiltersField: (state, {payload}: PayloadAction<Partial<ResultsFilters>>) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
    clearFilters: (state) => {
      state.filters = initialFilters;
    },
  },
  extraReducers(builder) {
    builder.addCase(globalStateResetAction, () => {
      return initialState;
    });
    builder.addCase(addManualResult.fulfilled, (state, {payload}) => {
      const newResult = {
        id: payload.id,
        date: payload.date,
        reported: payload.date,
        collected: payload.date,
        type: ResultType.MANUAL,
        healthCases: [],
      };
      if (!state.results) {
        state.results = createNormalizedState([newResult]);
        state.filteredResults = [newResult];
      } else {
        updateNormalizedState(state.results, [newResult], undefined, 0);
        state.filteredResults = [newResult, ...state.filteredResults];
      }

      state.testResults = [];
    });
    builder.addCase(updateStorageFile.fulfilled, (state, {payload}) => {
      const {date, healthCases, id} = payload;

      if (state.results?.byID[id]) {
        state.results.byID[id] = {
          ...state.results.byID[id],
          date,
          collected: date,
          reported: date,
          healthCases: healthCases.map(({caseDate, id, name, description}) => ({
            id,
            caseDate,
            name,
            description,
            objectCount: 1,
          })),
        };
      }
    });
  },
});

export const resultsStateName = slice.name;
export const resultsActions = slice.actions;
export const resultsReducer = slice.reducer;

export const selectDynamicsData = createSelector(
  (state: RootState) => state.results.testResults,
  (testResults) => ({testResults})
);
