import {Stack} from '@mui/material';
import {PulseLoader} from '@/components/PulseLoader';

import {loaderContainer} from './styles';

export const FullContainerLoader = () => {
  return (
    <Stack sx={loaderContainer}>
      <PulseLoader loading />
    </Stack>
  );
};
