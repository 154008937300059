import {useMediaQuery, useTheme} from '@mui/material';

// eslint-disable-next-line
/**
 * @deprecated use ui-kit const {<breakpoint>, <breakpoint>Only} = useBreakpoint('<breakpoint>')
 * or - if there is still need to use many breakpoints at once - you can use useBreakpoints(), also from ui-kit
 */
export const useMQuery = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tabletPortrait = useMediaQuery(theme.breakpoints.only('sm'));
  const tabletLandscape = useMediaQuery(theme.breakpoints.only('md'));
  const tabletWorkaround = useMediaQuery(theme.breakpoints.down('lg'));
  const laptop = useMediaQuery(theme.breakpoints.down('xl'));
  const lg = useMediaQuery(theme.breakpoints.only('lg'));
  const xl = useMediaQuery(theme.breakpoints.only('xl'));
  const xxl = useMediaQuery(theme.breakpoints.only('xxl'));
  const tbl = tabletWorkaround && !mobile;
  const definitions = {
    mobile,
    tablet: tbl,
    tabletPortrait,
    tabletLandscape,
    laptop,
    desktop: !mobile && !tbl,
    lg,
    xl,
    xxl,
  };
  return definitions;
};
