import {SxProps, Theme} from '@mui/material';
import {initialColors} from '@/theme';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  wrapper: {
    fontSize: '14px',
    backgroundColor: '#fff',
    flexGrow: 1,
  },
  header: {
    display: 'grid',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      svg: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    borderBottom: '1px solid #dff2f5',
    padding: '16px 36px',
    fontWeight: 700,
    letterSpacing: 0,
    color: '#4b5563',
    wordBreak: 'break-word',
    paddingTop: 0,
    paddingBottom: 0,
    height: {xs: 48, md: 54},
  },
  body: {
    overflowY: 'auto',
  },
  emptySearch: (t) => ({
    borderTop: `1px solid ${initialColors.blueLight}`,
    height: 'calc(100vh - 420px)',
    padding: 14,
    [t.breakpoints.down('xl')]: {
      height: 'calc(100vh - 385px)',
    },
    [t.breakpoints.down('lg')]: {
      height: 'calc(100vh - 430px)',
    },
    [t.breakpoints.down('sm')]: {
      height: 'calc(100vh - 450px)',
      border: `1px solid ${initialColors.blueLight}`,
      padding: 12,
    },
  }),
};
