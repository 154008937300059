import {styled, TableBody} from '@mui/material';
import {alpha} from '@/theme/utils';

export const TBody = styled(TableBody)(
  ({theme}) => ({
    tr: {
      outline: `thin solid ${alpha(theme.palette.secondary.main, 14)}`,
      '&:hover': {backgroundColor: `${alpha(theme.palette.secondary.main, 7)}`, cursor: 'pointer'},
      td: {
        padding: '9px 12px',
        '&:first-child': {paddingLeft: '24px'},
        '&:last-child': {paddingRight: '24px'},
      },
    },
  }),
);
