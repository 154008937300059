import {
  Cross,
  Calendar,
  Bell,
  MenuIcons,
  Info,
  Message,
  Arrow,
  Check,
  Alert,
  Search,
  Time,
  Notification,
  Priority,
  User,
  Logout,
  Star,
  Download,
} from './components';

export const Icons = {
  Search,
  Cross,
  Calendar,
  Bell,
  MenuIcons,
  Info,
  Alert,
  Message,
  Arrow,
  Check,
  Time,
  Notification,
  Priority,
  User,
  Logout,
  Star,
  Download,
};
