import {ProfileUpdatedData} from '@/api';
import {useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '@/store';

import {accountActions} from './slice';

const useAccountState = () => useSelector((state: RootState) => state.account);

export const useAccount = () => {
  const accountState = useAccountState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!accountState.account) {
      dispatch(accountActions.requestAccount());
    }
  }, [accountState.account, dispatch]);

  return {
    account: accountState.account,
    fetching: accountState.fetching?.account,
  };
};

export const useAccountProfile = () => {
  const accountState = useAccountState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!accountState.accountProfile) {
      dispatch(accountActions.requestAccountProfileData());
    }
  }, [dispatch]);

  return {
    accountProfile: accountState.accountProfile,
    fetching: accountState.fetching?.profile,
  };
};

export const useLogout = () => {
  const dispatch = useDispatch();
  return () => dispatch(accountActions.doLogout());
};

export const useAccountForm = () => {
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      update: ({data}: {data: ProfileUpdatedData}) => {
        if (!data) {
          console.error('[useAccountForm] data is undefined');
        } else {
          dispatch(accountActions.updateAccountProfile({data}));
        }
      },
    };
  }, [dispatch]);
  return {
    ...callbacks,
  };
};
