import {Typography} from '@mui/material';
import {Dialog, DialogTitle} from '@/components/Dialog';
import {IMenuItem} from '@/components/MenuList/MenuList';
import {useSupportItems} from '@/components/SupportMenu/SupportMenuContainer';
import {HEADER_DATA_TEST_ID} from '@/features/layouts/MainLayout/testIds';
import {useTranslate} from '@/i18n/useTranslate';
import {Icons} from '@/shared/assets/icons';
import Language from '@/shared/assets/icons/flag-us.svg?react';
import {GLOB_NOTIFICATIONS_PER_PAGE} from '@/shared/constants';
import {envs} from '@/shared/constants/envs';
import {useMQuery} from '@/shared/hooks/useMQuery';
import {getName} from '@/shared/utils/getName';
import {testId} from '@/shared/utils/testId';
import {useAppDispatch, useAppSelector} from '@/store';
import {globalNotificationsActions} from '@/store/globalNotifications/slice';
import {notifyActions} from '@/store/notifications/slice';
import {useUser} from '@/store/user/hooks';
import {FC, RefObject, useEffect, useMemo, useRef, useState} from 'react';
import {useAccount, useLogout} from '@/store/account/hooks';

import {GlobalNotifications} from './GlobalNotifications';
import {GlobalNotificationBtn} from './GlobalNotifications/GlobalNotificationBtn';
import {ProfileMenuView} from './ProfileMenu';

const useMenuItems = () => {
  const {account} = useAccount();
  const logout = useLogout();
  const {handleAboutSystemModal} = useUser();
  const {mobile, tablet} = useMQuery();

  const title = getName(account, {initialsOnlyMiddleName: true, withoutMiddleName: true});

  const supportItems = useSupportItems();
  const additionalItems = useMemo(() => {
    if (mobile || tablet) {
      return supportItems.menuItems;
    }
    return [];
  }, [supportItems, mobile, tablet]);

  const menuItems: IMenuItem[] = useMemo(() => {
    return [
      {
        label: 'English',
        iconLeft: <Language />,
        dataTestId: HEADER_DATA_TEST_ID.headerProfileMenuLanguage,
      },
      {
        label: 'Passport',
        to: envs.PASSPORT_WEB_URL,
        dataTestId: HEADER_DATA_TEST_ID.headerProfileMenuPassport,
      },
      {
        label: 'About system',
        onClick: handleAboutSystemModal,
        dataTestId: HEADER_DATA_TEST_ID.headerProfileMenuAbout,
      },
      ...additionalItems,
      {
        label: 'Log out',
        iconRight: <Icons.Logout.X24 />,
        onClick: logout,
        dataTestId: HEADER_DATA_TEST_ID.headerProfileMenuLogout,
      },
    ];
  }, [handleAboutSystemModal, logout, additionalItems]);

  return {menuItems, title};
};

export const ProfileMenuContainer: FC = () => {
  const {t} = useTranslate('common');
  const {t: notifiTranslate} = useTranslate('notifications');
  const {title, menuItems} = useMenuItems();
  const {handleAboutSystemModal, isModalOpen} = useUser();
  const {account} = useAccount();
  const {hasUnreadNotification, isError, notifications} = useAppSelector(
    (state) => state.globalNotifications,
  );

  const dispatch = useAppDispatch();

  const [isShowNotifications, setIsShowNotifications] = useState(false);
  const notificationModalRef = useRef<HTMLDivElement>(null);
  const notificationBtnRef = useRef<HTMLButtonElement>(null);

  const handleToggleModal = (e: React.MouseEvent) => {
    if (notificationBtnRef?.current?.contains(e.target as Node)) {
      if (!isShowNotifications && !notifications.length) {
        void dispatch(
          globalNotificationsActions.fetchGlobalNotifications({
            startPage: 0,
            perPage: GLOB_NOTIFICATIONS_PER_PAGE,
          }),
        ).then((res) => {
          if (globalNotificationsActions.fetchGlobalNotifications.fulfilled.match(res)) {
            setIsShowNotifications(true);
          } else {
            dispatch(
              notifyActions.showNotifications([
                {
                  id: '',
                  type: 'error',
                  text: t('SMTH_WENT_WRONG', {link: envs.PATIENT_CONTACT_SUPPORT_URL}),
                },
              ]),
            );
          }
        });
      } else if (!isError && notifications.length > 0) {
        setIsShowNotifications((prev) => !prev);
      }
    }
  };

  const handleCloseModal = () => setIsShowNotifications(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isOutside = (ref: RefObject<HTMLElement>) =>
        ref.current && !ref.current.contains(event.target as Node);

      const isToastClick = (event.target as HTMLElement).closest('.Toastify__toast-container');

      if (isOutside(notificationModalRef) && isOutside(notificationBtnRef) && !isToastClick) {
        setIsShowNotifications(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setIsShowNotifications]);

  return (
    <>
      <ProfileMenuView name={title} menu={menuItems} src={account?.avatarUrl} />
      <GlobalNotificationBtn
        hasUnread={hasUnreadNotification}
        open={handleToggleModal}
        ref={notificationBtnRef}
        data-test-id={testId(HEADER_DATA_TEST_ID.headerNotificationButton)}
      />
      {isShowNotifications && (
        <GlobalNotifications
          ref={notificationModalRef}
          close={handleCloseModal}
          t={notifiTranslate}
        />
      )}
      <Dialog
        open={isModalOpen}
        onClose={handleAboutSystemModal}
        size="xl"
        data-test-id={testId(HEADER_DATA_TEST_ID.headerAboutSystemDialog)}
      >
        <DialogTitle
          onClose={handleAboutSystemModal}
          noContent={false}
          closeButtonDataTestId={testId(HEADER_DATA_TEST_ID.headerAboutSystemHeaderCloseButton)}
        >
          {t('ABOUT_SYSTEM')}
        </DialogTitle>
        <Typography variant="14_20_400" sx={{padding: '12px 24px'}}>
          {t('ABOUT_SYSTEM_MODAL')}
        </Typography>
      </Dialog>
    </>
  );
};
