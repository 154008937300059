import {styled, TableHead} from '@mui/material';
import {alpha} from '@/theme/utils';

export const THead = styled(TableHead)(({theme}) => ({
  tr: {
    outline: `thin solid ${alpha(theme.palette.secondary.main, 14)}`,
    th: {
      padding: '14px 10px',
      '&:first-child': {paddingLeft: '24px !important'},
      '&:last-child': {paddingRight: '24px !important'},
    },
  },
}),
);
