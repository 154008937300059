import DownX16 from '../16x16/arrow-down.svg?react';
import BackX24 from '../24x24/arrow-back.svg?react';
import DownX24 from '../24x24/arrow-down.svg?react';
import GoX24 from '../24x24/arrow-go.svg?react';

export const Arrow = {
  Back: {
    X24: BackX24,
  },
  Down: {
    X16: DownX16,
    X24: DownX24,
  },
  Go: {
    X24: GoX24,
  },
};
