import {Box, Drawer, IconButton, Stack, SxProps, Typography, useTheme} from '@mui/material';
import {SIDE_MENU_DATA_TEST_ID} from '@/features/side-menu/testIds';
import {ROUTERS_PATH} from '@/routers/const';
// NOTE temporary hide import AppointmentsIcon from '@/icons/24x24/appointments.svg';
// NOTE temporary hide import FavoritesIcon from '@/icons/24x24/favorites.svg';
import ConnectionsIcon from '@/shared/assets/icons/24x24/connections.svg?react';
import HideMenu from '@/shared/assets/icons/24x24/hide_panel.svg?react';
import MessageIcon from '@/shared/assets/icons/24x24/message.svg?react';
import ResultIcon from '@/shared/assets/icons/24x24/results.svg?react';
import ShowMenu from '@/shared/assets/icons/24x24/show_panel.svg?react';
import StorageIcon from '@/shared/assets/icons/24x24/storage.svg?react';
import HealthCasesIcon from '@/shared/assets/icons/health-cases.svg?react';
import JournalIcon from '@/shared/assets/icons/icon_chart_bar.svg?react';
import InsuranceIcon from '@/shared/assets/icons/incurance.svg?react';
import OverviewIcon from '@/shared/assets/icons/overview.svg?react';
import SettingsIcon from '@/shared/assets/icons/settings-menu.svg?react';
import TasksIcon from '@/shared/assets/icons/tasks.svg?react';
import {useMQuery} from '@/shared/hooks/useMQuery';
import {testId} from '@/shared/utils/testId';
import {useMessengerStatistic} from '@/store/messenger/hooks';
import {useTranslate} from '@/i18n/useTranslate';
import {FC, useState} from 'react';
import {useMatch} from 'react-router-dom';

import {MenuItem} from './Menu';
import {classes, MenuList} from './SideMenuContainer.style';
import {useAppDispatch} from '@/store';
import {resultsActions} from '@/store/results/slice';

interface HideMenuIconProps {
  open: boolean;
  onClick: () => void;
  sx?: SxProps;
  color?: 'secondary';
}

export const DRAWER_WIDTH_OPEN = 340;
export const DRAWER_WIDTH_CLOSE = 64;

const HideMenuIcon: React.FC<HideMenuIconProps> = ({onClick, open, sx, color}) => {
  return (
    <IconButton
      onClick={onClick}
      sx={sx}
      data-test-id={testId(SIDE_MENU_DATA_TEST_ID.sideMenuShowHideButton)}
    >
      {open ? <HideMenu /> : <ShowMenu color={color} />}
    </IconButton>
  );
};

interface SideMenuItem {
  pathname: string;
  search?: string;
  exact?: boolean;
  header?: boolean;
  selected?: boolean;
  title: string;
  subtitle?: string;
  icon?: JSX.Element;
  count?: number;
  dataTestId: string;
}

const useMenuItems = () => {
  // TODO Clean up common translations file
  const {t} = useTranslate('common');
  const dynamicPage = useMatch(ROUTERS_PATH.DYNAMICS);
  const detailsPage = useMatch(ROUTERS_PATH.RESULT_DETAILS);
  const {unreadCount} = useMessengerStatistic();
  return [
    {
      title: t('OVERVIEW'),
      pathname: ROUTERS_PATH.OVERVIEW,
      header: true,
      icon: <OverviewIcon />,
      dataTestId: SIDE_MENU_DATA_TEST_ID.sideMenuOverview,
    },
    /* NOTE temporary hide
    // {
    //   title: t('APPOINTMENTS'),
    //   pathname: ROUTERS_PATH.APPOINTMENTS,
    //   header: true,
    //   icon: <AppointmentsIcon/>,
    // },
    */
    {
      title: t('TASKS'),
      pathname: ROUTERS_PATH.TASKS,
      header: true,
      icon: <TasksIcon />,
      dataTestId: SIDE_MENU_DATA_TEST_ID.sideMenuTasks,
    },
    {
      title: t('RESULTS'),
      pathname: ROUTERS_PATH.RESULTS,
      header: true,
      icon: <ResultIcon />,
      selected: Boolean(dynamicPage) || Boolean(detailsPage),
      dataTestId: SIDE_MENU_DATA_TEST_ID.sideMenuResults,
    },
    {
      title: t('MESSAGES'),
      pathname: ROUTERS_PATH.MESSAGES,
      icon: <MessageIcon />,
      count: unreadCount,
      header: true,
      dataTestId: SIDE_MENU_DATA_TEST_ID.sideMenuMessages,
    },
    {
      title: t('JOURNAL'),
      pathname: ROUTERS_PATH.JOURNAL,
      icon: <JournalIcon />,
      header: true,
      dataTestId: SIDE_MENU_DATA_TEST_ID.sideMenuJournal,
    },
    /* NOTE temporary hide
    {
      title: t('FAVORITES'),
      pathname: ROUTERS_PATH.FAVORITES,
      header: true,
      icon: <FavoritesIcon/>,
    },
    */
    {
      title: t('INSURANCE'),
      pathname: ROUTERS_PATH.INSURANCE,
      header: true,
      icon: <InsuranceIcon />,
      dataTestId: SIDE_MENU_DATA_TEST_ID.sideMenuInsurance,
    },
    {
      title: t('CONNECTIONS'),
      pathname: ROUTERS_PATH.CONNECTIONS_RELATIONS,
      header: true,
      icon: <ConnectionsIcon />,
      dataTestId: SIDE_MENU_DATA_TEST_ID.sideMenuConnections,
    },
    {
      title: t('HEALTH_CASES'),
      pathname: ROUTERS_PATH.HEALTH_CASES,
      header: true,
      icon: <HealthCasesIcon />,
      dataTestId: SIDE_MENU_DATA_TEST_ID.sideMenuHealthCases,
    },
    {
      title: t('STORAGE'),
      pathname: ROUTERS_PATH.STORAGE,
      header: true,
      icon: <StorageIcon />,
      dataTestId: SIDE_MENU_DATA_TEST_ID.sideMenuStorage,
    },
    {
      title: t('SETTINGS'),
      pathname: ROUTERS_PATH.SETTINGS,
      header: true,
      icon: <SettingsIcon />,
      dataTestId: SIDE_MENU_DATA_TEST_ID.sideMenuSettings,
    },
  ] as SideMenuItem[];
};

interface SideManuContainerProps {
  className?: string;
  open: boolean;
  toggleMenu: () => void;
}

export const SideMenuContainer: FC<SideManuContainerProps> = ({className, open, toggleMenu}) => {
  const menuItems = useMenuItems();
  const theme = useTheme();
  const {mobile, tablet} = useMQuery();
  const dispatch = useAppDispatch();

  const [currPath, setCurrPath] = useState<string>(window.location.pathname);
  const [needClear, setNeedClear] = useState(false);

  const handleClearFiltersStates = () => {
    dispatch(resultsActions.clearFilters());
  };

  const handleClickMenuItem = (pathName: string) => {
    setNeedClear(currPath !== pathName);
    setCurrPath(pathName);

    handleClearFiltersStates();
  };

  return (
    <Drawer sx={classes.drawer(open, theme)} variant="permanent">
      <MenuList className={className}>
        {menuItems.map((m, i) => {
          return (
            <MenuItem
              open={open}
              key={i}
              title={m.title}
              subtitle={m.subtitle}
              header={m.header}
              end={m.exact}
              icon={m.icon}
              selected={m.selected}
              to={{pathname: m.pathname}}
              count={m.count}
              state={{needClear}}
              onClick={() => handleClickMenuItem(m.pathname)}
              data-test-id={testId(m.dataTestId)}
            />
          );
        })}
      </MenuList>
      {!mobile && !tablet && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            transition: 'width 0.3s',
            width: open ? '100%' : DRAWER_WIDTH_CLOSE,
          }}
        >
          <Stack direction="row" alignItems="center" gap="6px">
            <Typography
              fontSize={14}
              color="secondary"
              fontWeight="medium"
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              Hide panel
            </Typography>
            <HideMenuIcon open={open} onClick={toggleMenu} sx={{marginRight: '12px'}} />
          </Stack>
        </Box>
      )}
    </Drawer>
  );
};
