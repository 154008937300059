import {Breadcrumbs as MuiBreadcrumbs, SxProps, styled} from '@mui/material';
import ArrowRightIcon from '@/shared/assets/icons/arrow-right.svg?react';
import {memo, PropsWithChildren} from 'react';

export const Breadcrumbs = memo(({children, className, sx}: PropsWithChildren<{ className?: string, sx?: SxProps }>) => {
  return (
    <StyledBreadcrumbs separator={<ArrowRightIcon />} className={className} sx={sx}>
      {children}
    </StyledBreadcrumbs>
  );
});
Breadcrumbs.displayName = 'Breadcrumbs';

export const StyledBreadcrumbs = styled(MuiBreadcrumbs)(({theme}) => ({
  color: theme.palette.grey[800],
  'a, a:visited': {
    color: theme.palette.grey[800],
  },
  '.MuiBreadcrumbs-separator': {
    marginLeft: 3,
    marginRight: 3,
  },
  '.MuiBreadcrumbs-ol': {
    flexWrap: 'initial',
  },
  '.MuiBreadcrumbs-li > p': {
    whiteSpace: 'nowrap',
  },
  overflow: 'auto',
  '*': {
    fontWeight: 500,
    fontSize: 16,
    marginBottom: 0,
    marginTop: 0,
  },
}));
