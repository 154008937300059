export const envs = {
  get BOOKING_WEB_URL (): string {
    return (
      window.env?.BOOKING_WEB_URL ??
      import.meta.env.VITE_BOOKING_WEB_URL ??
      ''
    );
  },
  get PASSPORT_WEB_URL (): string {
    return (
      window.env?.PASSPORT_WEB_URL ??
      import.meta.env.VITE_PASSPORT_WEB_URL ??
      ''
    );
  },
  get PASSPORT_IDENTITY_URL (): string {
    return (
      window.env?.PASSPORT_IDENTITY_URL ??
      import.meta.env.VITE_PASSPORT_IDENTITY_URL ??
      ''
    );
  },
  get PATIENT_USER_GUIDE_URL (): string {
    return (
      window.env?.PATIENT_USER_GUIDE_URL ??
      import.meta.env.VITE_PATIENT_USER_GUIDE_URL ??
      ''
    );
  },
  get PATIENT_CONTACT_SUPPORT_URL (): string {
    return (
      window.env?.PATIENT_CONTACT_SUPPORT_URL ??
      import.meta.env.VITE_PATIENT_CONTACT_SUPPORT_URL ??
      ''
    );
  },
  get BASE_API (): string {
    return (
      window.env?.BASE_API ??
      import.meta.env.VITE_BASE_API ??
      ''
    );
  },
  get GOOGLE_TAG_MANAGER_ID (): string {
    return (
      window.env?.GOOGLE_TAG_MANAGER_ID ??
      import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID ??
      ''
    );
  },
  get GOOGLE_API_KEY (): string {
    return (
      window.env?.GOOGLE_API_KEY ??
      import.meta.env.VITE_GOOGLE_API_KEY ??
      ''
    );
  },
  get GOOGLE_RECAPTCHA_KEY (): string {
    return (
      window.env?.GOOGLE_RECAPTCHA_KEY ??
      import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY ??
      ''
    );
  },
  get DISPLAY_DATA_TEST_ID (): boolean {
    return (
      window.env?.DISPLAY_DATA_TEST_ID === 'true' ||
      import.meta.env.VITE_DISPLAY_DATA_TEST_ID === 'true' ||
      false
    );
  },
};
