import {Typography} from '@mui/material';
import {testId} from '@/shared/utils/testId';
import {MutableRefObject, FC} from 'react';

import {UlContainer, MenuLink, MenuIcon, ListItem} from './styles';

export interface IMenuItem {
  to?: string
  onClick?: VoidFunction
  label: string
  iconRight?: JSX.Element
  iconLeft?: JSX.Element
  title?: string
  dataTestId: string
}

interface IDropdownMenuProps {
  open: boolean
  menu: IMenuItem[]
  onClose: () => void
  parentRef: MutableRefObject<HTMLElement | null>
}

export const MenuList: FC<IDropdownMenuProps> = ({
  open,
  menu,
  onClose: _,
  parentRef,
  ...props
}) => {
  if (!open) {
    return null;
  }
  const rect = parentRef.current?.getBoundingClientRect();
  const coords = {
    top: (rect?.bottom ?? 0) + 5,
    right: window.document.body.offsetWidth - (rect?.right ?? 0),
  };
  return (
    <UlContainer
      {...props}
      style={coords}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {menu.map(({to, onClick, label, iconRight, iconLeft, title, dataTestId}, i) => {
        return (
          <ListItem key={i}>
            <MenuLink
              target={to ? '_blank' : undefined}
              href={to}
              onClick={
                to
                  ? () => {}
                  : (e) => {
                    e.preventDefault();
                    onClick?.();
                  }
              }
              isIconRight={!!iconRight}
              isIconLeft={!!iconLeft}
              data-test-id={testId(dataTestId)}
            >
              <Typography variant={title ? '14_18_700' : '14_18_500'}>
                {iconLeft && <MenuIcon isIconLeft>{iconLeft}</MenuIcon>}
                {label}
                {iconRight && <MenuIcon>{iconRight}</MenuIcon>}
              </Typography>
              {title && <Typography variant="12_18_500">{title}</Typography>}
            </MenuLink>
          </ListItem>
        );
      })}
    </UlContainer>
  );
};
