import {Box, Typography} from '@mui/material';
import {useTranslate} from '@/i18n/useTranslate';
import CodeSignImage from '@/shared/assets/images/codeSign.png';
import ComingSoonImage from '@/shared/assets/images/coming-soon-mobile.png';
import ProgrammerImage from '@/shared/assets/images/programmer.png';
import {useMQuery} from '@/shared/hooks';
import {paletteColors} from '@/theme';

import {sx} from './styles';

export const ComingSoon = () => {
  const {tablet, mobile} = useMQuery();
  const {t, ready} = useTranslate('common');

  if (mobile) {
    return (
      <Box sx={sx.mainWrapper}>
        <Box
          component='img'
          src={ComingSoonImage}
          sx={{width: '100%', height: '100%'}}
        />
      </Box>
    );
  }

  return (
    <Box sx={sx.mainWrapper}>
      {ready && (
        <Box sx={sx.comingSoonWrapper}>
          <Typography
            variant='h1'
            sx={sx.mainText}
          >
            {t('COMING_SOON')}
          </Typography>
          <Typography
            variant={tablet ? '14_18_500' : '18_24_500'}
            color={paletteColors.grey[800]}
          >
            {t('SECTION_IS_UNDER_DEVELOPMENT')}
          </Typography>
          <Box
            component="img"
            sx={sx.codeSignImage}
            src={CodeSignImage}
            alt="code sign"
          />
        </Box>
      )}
      <Box
        component="img"
        src={ProgrammerImage}
        alt="coming soon"
        sx={sx.programmerImage}
      />
    </Box>
  );
};
