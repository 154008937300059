import {Profile} from '@/api/__generated__/webApi';
import {apiClient} from '@/client/ApiClient';

import {AccountDetails, Dashboard, ProfileUpdatedData} from './models';

export const getAccount = async () => {
  const response = await apiClient.get<AccountDetails>('/api/account');
  return response;
};
export const getDashboard = async ({dashboardInterval}: {dashboardInterval: number}) => {
  const response = await apiClient.get<Dashboard>('/dashboard', {params: dashboardInterval});
  return response.data;
};
export const getAccountProfile = async () => {
  const response = await apiClient.get<Profile>('/api/account/profile');
  return response.data;
};
export const updateAccountProfile = async ({data}: {data: ProfileUpdatedData}) => {
  const response = await apiClient.post('/api/account/update-contact', data);
  return response.data;
};

export * as accountApi from './';
export * from './models';
