/** @jsxImportSource @emotion/react */
import {css as _, IconButton, IconButtonProps, styled} from '@mui/material';
import IconSVGDots from '@/shared/assets/images/icons/icon_dots_vertical.svg?react';
import {FC} from 'react';
// in accordance with https://www.figma.com/file/SXWATb7HLZFlMiuuB6ZIJL/Vivica-UI-Kit?type=design&node-id=8612-43650&t=g1ceGhp9nUVLY9QM-4
// but as the case currently fits only for Dots button, I will not move it to separate file. Please do so if there will be more of the cases
// where the button can be applied
export const GrayishIconButton = styled(IconButton)(
  ({theme: t}) => _`
  path {
    fill: ${t.palette.grey[400]};
  }
  @media (hover: hover) {
    &:hover {
      path {
        fill: ${t.palette.grey[500]};
      }
    }
  }
  &:active {
    path {
      fill: ${t.palette.grey[700]}
    }
  }
  &:disabled {
    path {
      fill: ${t.palette.grey[200]};
    }
  }
`);
export const DotsButton: FC<IconButtonProps> = (props) => {
  return (
    <GrayishIconButton
      css={_`border-radius: 50%; padding: 4px; .mobile-layout & {padding: 8px;}`}
      {...props}
    >
      <IconSVGDots />
    </GrayishIconButton>
  );
};
