import {styled} from '@mui/material';
import GlobalNotificationIcon from '@/shared/assets/icons/notification-icon.svg?react';

export const StyledGlobalNotificationIcon = styled(GlobalNotificationIcon)<{ hasUnread: boolean }>(
  ({hasUnread}) => ({
    '.new': {
      display: hasUnread ? 'block' : 'none',
    },
  }),
);
