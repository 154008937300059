import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  ResultDetail,
  RelationElement,
} from '@/api';
import {
  DynamicItem, RelationContactBatchCreateRequest,
  RelationRequestCreateRequest,
  RelationType,
} from '@/api/__generated__/webApi';
import {InsuranceCarriersList, InsurancePlansList} from '@/api/insurances';
import {
  BaseSentRelationRequestResultDTO,
  EditRelationAddressData,
  EditRelationContactData,
  PrevAddRelationData,
  RelationContactSearch,
  RelationContactSearchResult,
  RelationContactSimilarError,
  RelationHealthcareInsurancePolicyChangeRequest,
  RelationProfile,
  RelationRequests,
  Result,
} from '@/api/relations';
import {RootState} from '@/store';
import {NormalizedState} from '@/store/common/normalized';
import {PartialRecord, UUID, ValidationErrorType} from '@/types';

import {createNormalizedState, updateNormalizedState} from '../common/normalized';
import {DynamicTestTransformed} from '../results/helpers';

export const PER_PAGE = 30;

export type TRequestNPIOrgFlowSteps =
  | 'init'
  | 'open'
  | 'loading'
  | 'running'
  | 'success'
  | 'error';
export type TRequestInsuranceFlowSteps =
  | 'init'
  | 'open'
  | 'loading'
  | 'noInsurances'
  | 'twoInsurances'
  | 'onlySecondaryInsurance'
  | 'onlyPrimaryInsurance'
  | 'SecondaryInsuranceAndPrimaryFields'
  | 'PrimaryInsuranceAndSecondaryFields'
  | 'onlyPrimaryFields'
  | 'onlySecondaryFields'
  | 'twoInsurancesFields';
type TFetching = 'common' | 'account' | 'dashboard' | 'profile' | 'relation' | 'dynamics';
export interface IRelationState {
  relation: RelationProfile | null
  results: Result[] | null
  resultDetails: NormalizedState<ResultDetail> | null
  dynamics: DynamicItem[] | null
  fetching: PartialRecord<TFetching, boolean> | null
  save: boolean | null
  brokeRelationsStatus: Record<string, number | null> | null
  error?: ValidationErrorType | null
  step: TRequestNPIOrgFlowSteps
  insuranceStep: TRequestInsuranceFlowSteps
  primaryInsuranceCarriers: InsuranceCarriersList[] | null
  secondaryInsuranceCarriers: InsuranceCarriersList[] | null
  primaryInsurancePlans: InsurancePlansList[] | null
  secondaryInsurancePlans: InsurancePlansList[] | null
  relations: RelationElement[] | null
  relationRequests: RelationRequests | null
  relationContacts: RelationContactSearchResult[] | null
  relationContactsHasNext: boolean | null
  errorStatus?: number | null
  contacts: {
    filter: string | null
    perPage: number | null
    policyNumber?: string | null
    recaptcha?: string | null
    relationType: string | null
    startPage?: number | null
  }
  successCreatedContact: BaseSentRelationRequestResultDTO | null
  failureCreatedContact: RelationContactSimilarError | null
  birthOfDateError: string | null
  testResults: DynamicTestTransformed[]
  sendResultStep: TRequestNPIOrgFlowSteps
  emailError: string | null
  lastFailedEmail: string | null
  prevAddRalationData: PrevAddRelationData
  hasInsuranceError: boolean | null
}
const initialState: IRelationState = {
  relation: null,
  results: null,
  resultDetails: null,
  dynamics: null,
  fetching: null,
  save: null,
  brokeRelationsStatus: null,
  step: 'init',
  insuranceStep: 'noInsurances',
  sendResultStep: 'init',
  primaryInsuranceCarriers: null,
  primaryInsurancePlans: null,
  secondaryInsuranceCarriers: null,
  secondaryInsurancePlans: null,
  relations: null,
  relationRequests: null,
  relationContacts: null,
  relationContactsHasNext: null,
  contacts: {
    filter: null,
    perPage: null,
    policyNumber: null,
    recaptcha: null,
    relationType: null,
    startPage: null,
  },
  successCreatedContact: null,
  failureCreatedContact: null,
  birthOfDateError: null,
  emailError: null,
  lastFailedEmail: null,
  testResults: [],
  errorStatus: null,
  prevAddRalationData: {
    relationType: null,
    policyId: null,
    searchBy: null,
    isSearched: false,
  },
  hasInsuranceError: null,
};

export const relations = createSlice({
  name: 'relations',
  initialState,
  reducers: {
    requestRelations () {},
    requestRelationRequests () {},
    requestResultDetails (_state, _action: PayloadAction<{relationId: UUID, resultId: UUID}>) {},
    requestResultsById (_state, _action: PayloadAction<{relationId: UUID}>) {},
    requestDynamics (_state, _action: PayloadAction<{relationId: UUID}>) {},
    requestRelation (_state, _action: PayloadAction<{relationId: UUID}>) {},
    requestPrimaryInsuranceCarriers (_state, _action: PayloadAction<{filter: string}>) {},
    requestSecondaryInsuranceCarriers (_state, _action: PayloadAction<{filter: string}>) {},
    requestPrimaryInsurancePlans (_state, _action: PayloadAction<{insuranceCarrierId: string}>) {},
    requestSecondaryInsurancePlans (_state, _action: PayloadAction<{insuranceCarrierId: string}>) {},
    deleteInsurance (_state, _payload: PayloadAction<{relationId: UUID, data: RelationHealthcareInsurancePolicyChangeRequest}>) {},
    setBrokeRelationsStatus (state, {payload}: PayloadAction<{brokeRelationsStatus: Record<string, number | null>}>) {
      state.brokeRelationsStatus = payload.brokeRelationsStatus;
    },
    setEmailError (state, {payload}: PayloadAction<{error: string | null, email?: string | null}>) {
      state.emailError = payload.error;
      if (payload.email) {
        state.lastFailedEmail = payload.error ? payload.email : null;
      }
    },
    setClearEmailError (state) {
      state.emailError = null;
    },
    setBirthOfDateError (state, {payload}: PayloadAction<{error: string | null}>) {
      state.birthOfDateError = payload.error;
    },
    setContacts (state, {payload}: PayloadAction<{contacts: RelationContactSearch}>) {
      state.contacts = payload.contacts;
    },
    requestDeleteRelation (_state, _action: PayloadAction<{relations: RelationElement[], types: RelationType[], relationId: UUID}>) {},
    sendRelationRequest (_state, _action: PayloadAction<{data: RelationRequestCreateRequest}>) {},
    editRelationContactData (_state, _payload: PayloadAction<{ relationId: UUID, data: EditRelationContactData }>) {},
    editRelationAddressData (_state, _payload: PayloadAction<{relationId: UUID, data: EditRelationAddressData}>) {},
    searchRelationContacts (_state, _payload: PayloadAction<{data: RelationContactSearch}>) {},
    approveRelationRequest (_state, _action: PayloadAction<{requestId: UUID}>) {},
    declineRelationRequest (_state, _action: PayloadAction<{requestId: UUID}>) {},
    deleteRelationRequest (_state, _action: PayloadAction<{requestId: UUID}>) {},
    editRelationHealthPoliciesData (_state, _payload: PayloadAction<{relationId: UUID, data: RelationHealthcareInsurancePolicyChangeRequest}>) {},
    setRelationContacts (state, {payload}: PayloadAction<{relationContacts: RelationContactSearchResult[] | null}>) {
      if (Array.isArray(state.relationContacts) && Array.isArray(payload.relationContacts)) {
        state.relationContacts = [...state.relationContacts, ...payload.relationContacts];
      } else state.relationContacts = payload.relationContacts;
    },
    setSuccessCreatedContact (state, {payload}: PayloadAction<{successCreatedContact: BaseSentRelationRequestResultDTO}>) {
      state.successCreatedContact = payload.successCreatedContact;
    },
    setFailureCreatedContact (state, {payload}: PayloadAction<{failureCreatedContact: RelationContactSimilarError | null}>) {
      state.failureCreatedContact = payload.failureCreatedContact;
    },
    createRelationContact (_state, _payload: PayloadAction<{data: RelationContactBatchCreateRequest, force?: boolean}>) {},
    setRelationContactsHasNext (state, {payload}: PayloadAction<{relationContactsHasNext: boolean | null}>) {
      state.relationContactsHasNext = payload.relationContactsHasNext;
    },
    setPrimaryInsuranceCarriers (state, {payload}: PayloadAction<{insuranceCarriers: InsuranceCarriersList[]}>) {
      state.primaryInsuranceCarriers = payload.insuranceCarriers;
    },
    setPrimaryInsurancePlans (state, {payload}: PayloadAction<{insurancePlans: InsurancePlansList[]}>) {
      state.primaryInsurancePlans = payload.insurancePlans;
    },
    setSecondaryInsuranceCarriers (state, {payload}: PayloadAction<{insuranceCarriers: InsuranceCarriersList[]}>) {
      state.secondaryInsuranceCarriers = payload.insuranceCarriers;
    },
    setSecondaryInsurancePlans (state, {payload}: PayloadAction<{insurancePlans: InsurancePlansList[]}>) {
      state.secondaryInsurancePlans = payload.insurancePlans;
    },
    resetInsurancePlansAndCarriers (state, {payload}: PayloadAction<{resetValue: null}>) {
      state.secondaryInsurancePlans = payload.resetValue;
      state.secondaryInsuranceCarriers = payload.resetValue;
      state.primaryInsuranceCarriers = payload.resetValue;
      state.primaryInsurancePlans = payload.resetValue;
    },
    setRelations (state, {payload}: PayloadAction<{relations: RelationElement[]}>) {
      state.relations = payload.relations;
    },
    setRelationRequests (state, {payload}: PayloadAction<{relationRequests: RelationRequests}>) {
      state.relationRequests = payload.relationRequests;
    },
    setRelation (state, {payload}: PayloadAction<{relation: RelationProfile | null}>) {
      state.relation = payload.relation;
    },
    setContactRelation (state, {payload}: PayloadAction<{relation: RelationProfile}>) {
      state.relation = payload.relation;
    },
    setResults (state, {payload}: PayloadAction<{results: Result[] | null}>) {
      state.results = payload.results;
    },
    setDetails (state, {payload}: PayloadAction<ResultDetail>) {
      if (!state.resultDetails) {
        state.resultDetails = createNormalizedState([payload]);
      } else {
        updateNormalizedState(state.resultDetails, [payload]);
      }
    },
    setDynamics (state, {payload}: PayloadAction<DynamicItem[]>) {
      state.dynamics = payload;
    },
    setTranformedData (state, {payload}: PayloadAction<DynamicTestTransformed[]>) {
      state.testResults = payload;
    },
    setFetching (state, {payload}: PayloadAction<IRelationState['fetching']>) {
      if (!payload) {
        state.fetching = null;
      }
      state.fetching = {...state.fetching, ...payload};
    },
    setSave (state, {payload}: PayloadAction<IRelationState['save']>) {
      if (!payload) {
        state.save = null;
      }
      state.save = payload;
    },
    setStep (
      state,
      {payload}: PayloadAction<{ step: TRequestNPIOrgFlowSteps }>,
    ) {
      state.step = payload.step;
    },
    setInsuranceStep (
      state,
      {payload}: PayloadAction<{ step: TRequestInsuranceFlowSteps }>,
    ) {
      state.insuranceStep = payload.step;
    },
    setSendResultStep (
      state,
      {payload}: PayloadAction<{ step: TRequestNPIOrgFlowSteps }>,
    ) {
      state.sendResultStep = payload.step;
    },
    setCommonError (state, {payload}: PayloadAction<ValidationErrorType | null>) {
      state.error = payload;
    },
    setErrorStatus (state, {payload}: PayloadAction<number | null>) {
      state.errorStatus = payload;
    },
    sendResultsByEmail (_state, _payload: PayloadAction<{relationId: UUID, resultId: UUID, email: string}>) {},
    setPrevAddRelationData (state, {payload}: PayloadAction<PrevAddRelationData>) {
      state.prevAddRalationData = {...state.prevAddRalationData, ...payload};
    },
    setHasInsuranceError (state, {payload}: PayloadAction<boolean | null>) {
      state.hasInsuranceError = payload;
    },
  },
});

export const selectDynamicsData = createSelector(
  (state: RootState) => state.relations.testResults,
  (testResults) => ({testResults}),
);
export const relationsReducer = relations.reducer;
export const relationsActions = relations.actions;
export const relationsStoreName = relations.name;
