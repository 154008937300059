import {ROUTERS_PATH} from '@/routers';
import React, {lazy, Suspense} from 'react';
import './i18n/i18n';
import {Routes, Route, Navigate} from 'react-router-dom';

import {useCheckAcceptedCookies} from './features/cookies/redux/hooks';
import {MainLayout} from './features/layouts/MainLayout';
import NotFound from '@/pages/NotFound';
import Tasks from '@/pages/Tasks';
import Forbidden from '@/pages/Forbidden';
import {FullContainerLoader} from '@/components/FullContainerLoader';

const Results = lazy(() => import('./pages/Results'));
const Messages = lazy(() => import('./pages/Messages'));
const Result = lazy(() => import('./pages/Details'));
const Dynamics = lazy(() => import('./pages/Dynamics'));
const Insurance = lazy(() => import('./pages/Insurance'));
const Overview = lazy(() => import('./pages/Overview'));
const Settings = lazy(() => import('./pages/Settings'));
const Connections = lazy(() => import('./pages/Connections'));
const AddInsurance = lazy(() => import('./pages/Insurance/AddInsurance'));
const EditInsurance = lazy(() => import('./pages/Insurance/EditInsurance'));
const Storage = lazy(() => import('./pages/Storage'));
const HealthCases = lazy(() => import('./pages/HealthCases'));
const CalculatorBMI = lazy(() => import('./pages/BMI'));
const Accesses = lazy(() => import('./pages/Connections/fragments/Accesses'));
const AccessesAcceptedMobileLayout = lazy(
  () => import('./pages/Connections/fragments/Accesses/fragments/AccessesAcceptedMobileLayout')
);
const AccessesRequestsMobileLayout = lazy(
  () => import('./pages/Connections/fragments/Accesses/fragments/AccessesRequestsMobileLayout')
);
const AccessesResults = lazy(
  () => import('@/pages/Connections/fragments/Accesses/AccessesResults')
);
const Relations = lazy(() => import('./pages/Connections/fragments/Relations'));
const AddRelation = lazy(() => import('./pages/Connections/fragments/Relations/AddRelation'));
const CreateNewPatient = lazy(
  () => import('./pages/Connections/fragments/Relations/CreateNewPatient')
);
const RelationAbout = lazy(
  () => import('./pages/Connections/fragments/Relations/fragments/RelationAbout')
);
const RelationContacts = lazy(
  () => import('./pages/Connections/fragments/Relations/fragments/RelationContacts')
);
const RelationAboutEdit = lazy(
  () =>
    import(
      './pages/Connections/fragments/Relations/fragments/RelationEditLayouts/RelationAboutEdit'
    )
);
const RelationContactsEdit = lazy(
  () =>
    import(
      './pages/Connections/fragments/Relations/fragments/RelationEditLayouts/RelationContactsEdit'
    )
);
const RelationHealthPoliciesEdit = lazy(
  () =>
    import(
      './pages/Connections/fragments/Relations/fragments/RelationEditLayouts/RelationHealthPoliciesEdit'
    )
);
const RelationHealthPolicies = lazy(
  () => import('./pages/Connections/fragments/Relations/fragments/RelationHealthPolicies')
);
const RelationHealthPoliciesAdd = lazy(
  () =>
    import(
      './pages/Connections/fragments/Relations/fragments/RelationHealthPolicies/RelationHealthPoliciesAdd'
    )
);
const RelationMain = lazy(
  () => import('./pages/Connections/fragments/Relations/fragments/RelationMain')
);
const ChangeAccess = lazy(
  () =>
    import('./pages/Connections/fragments/Relations/fragments/RelationMain/fragments/ChangeAccess')
);
const RelationLayoutView = lazy(
  () =>
    import(
      './pages/Connections/fragments/Relations/fragments/RelationMain/fragments/RelationLayoutView'
    )
);
const RelationRelations = lazy(
  () => import('./pages/Connections/fragments/Relations/fragments/RelationRelations')
);
const RelationResults = lazy(
  () => import('./pages/Connections/fragments/Relations/fragments/RelationResults')
);
const ResultDetails = lazy(
  () => import('./pages/Connections/fragments/Relations/fragments/ResultDetails')
);
const ResultDynamics = lazy(
  () => import('./pages/Connections/fragments/Relations/fragments/ResultDynamics')
);
const HealthCase = lazy(() => import('./pages/HealthCase'));
const Journal = lazy(() => import('./pages/Journal'));
const PdfResults = lazy(() => import('./pages/PdfResults'));
const StorageDocument = lazy(() => import('./pages/StorageDocument'));

// NOTE temporary hide import {MobileAppointmentCard} from './pages/Appointments/AppointmentCard/MobileAppointmentCard';

// NOTE temporary hide import {MobileAppointmentCard} from './pages/Appointments/AppointmentCard/MobileAppointmentCard';

export const App: React.FC = () => {
  useCheckAcceptedCookies();
  return (
    <Routes>
      <Route path={ROUTERS_PATH.QR_RESULTS}>
        <Route
          path=""
          element={
            <Suspense fallback={<FullContainerLoader />}>
              <PdfResults />
            </Suspense>
          }
        />
        <Route path="*" element={<NotFound isSeparate />} />
      </Route>
      <Route element={<MainLayout />}>
        <Route path="/" element={<Navigate to={ROUTERS_PATH.OVERVIEW} />} />
        <Route path={ROUTERS_PATH.RESULTS}>
          <Route
            path=""
            element={
              <Suspense fallback={<FullContainerLoader />}>
                <Results />
              </Suspense>
            }
          />
          <Route path={ROUTERS_PATH.RESULT_DETAILS}>
            <Route
              path=""
              element={
                <Suspense fallback={<FullContainerLoader />}>
                  <Result />
                </Suspense>
              }
            />
            <Route
              path={ROUTERS_PATH.DYNAMICS}
              element={
                <Suspense fallback={<FullContainerLoader />}>
                  <Dynamics />
                </Suspense>
              }
            />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route
          path={ROUTERS_PATH.MESSAGES}
          element={
            <Suspense fallback={<FullContainerLoader />}>
              <Messages />
            </Suspense>
          }
        />
        <Route
          path={ROUTERS_PATH.JOURNAL}
          element={
            <Suspense fallback={<FullContainerLoader />}>
              <Journal />
            </Suspense>
          }
        />
        <Route
          path={ROUTERS_PATH.OVERVIEW}
          element={
            <Suspense fallback={<FullContainerLoader />}>
              <Overview />
            </Suspense>
          }
        />
        <Route
          path={ROUTERS_PATH.BMI}
          element={
            <Suspense fallback={<FullContainerLoader />}>
              <CalculatorBMI />
            </Suspense>
          }
        />
        {/* NOTE temporary hide <Route path={ROUTERS_PATH.APPOINTMENTS} element={<Appointments />} /> */}
        {/* NOTE temporary hide <Route path={ROUTERS_PATH.APPOINTMENT_CARD} element={<MobileAppointmentCard />} /> */}
        <Route path={ROUTERS_PATH.TASKS} element={<Tasks />} />
        <Route
          path={ROUTERS_PATH.HEALTH_CASES}
          element={
            <Suspense fallback={<FullContainerLoader />}>
              <HealthCases />
            </Suspense>
          }
        />
        <Route
          path={ROUTERS_PATH.HEALTH_CASE}
          element={
            <Suspense fallback={<FullContainerLoader />}>
              <HealthCase />
            </Suspense>
          }
        />
        <Route
          path={ROUTERS_PATH.HEALTH_CASE_ADD}
          element={
            <Suspense fallback={<FullContainerLoader />}>
              <HealthCase />
            </Suspense>
          }
        />
        <Route
          path={ROUTERS_PATH.CONNECTIONS_ADD_RELATION}
          element={
            <Suspense fallback={<FullContainerLoader />}>
              <AddRelation />
            </Suspense>
          }
        />
        <Route
          path={ROUTERS_PATH.CONNECTIONS_CREATE_A_NEW_PATIENT}
          element={
            <Suspense fallback={<FullContainerLoader />}>
              <CreateNewPatient />
            </Suspense>
          }
        />
        <Route
          path={ROUTERS_PATH.CONNECTIONS_ACCESSES_REQUESTS_ACCESS}
          element={
            <Suspense fallback={<FullContainerLoader />}>
              <AccessesResults />
            </Suspense>
          }
        />
        <Route
          path={ROUTERS_PATH.CONNECTIONS_ACCESSES_REQUESTS}
          element={
            <Suspense fallback={<FullContainerLoader />}>
              <AccessesRequestsMobileLayout />
            </Suspense>
          }
        />
        <Route
          path={ROUTERS_PATH.CONNECTIONS_ACCESSES_ACCEPTED}
          element={
            <Suspense fallback={<FullContainerLoader />}>
              <AccessesAcceptedMobileLayout />
            </Suspense>
          }
        />
        <Route path={ROUTERS_PATH.CONNECTIONS_ACCESSES}>
          <Route
            element={
              <Suspense fallback={<FullContainerLoader />}>
                <Connections />
              </Suspense>
            }
          >
            <Route
              path=""
              element={
                <Suspense fallback={<FullContainerLoader />}>
                  <Accesses />
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route path={ROUTERS_PATH.CONNECTIONS_RELATIONS}>
          <Route
            element={
              <Suspense fallback={<FullContainerLoader />}>
                <Connections />
              </Suspense>
            }
          >
            <Route
              path=""
              element={
                <Suspense fallback={<FullContainerLoader />}>
                  <Relations />
                </Suspense>
              }
            />
          </Route>
          <Route path={ROUTERS_PATH.CONNECTIONS_RELATION}>
            <Route
              path={ROUTERS_PATH.CONNECTIONS_RELATION_CHANGE_ACCESS}
              element={
                <Suspense fallback={<FullContainerLoader />}>
                  <ChangeAccess />
                </Suspense>
              }
            />
            <Route
              path=""
              element={
                <Suspense fallback={<FullContainerLoader />}>
                  <RelationMain />
                </Suspense>
              }
            >
              <Route
                path=""
                element={
                  <Suspense fallback={<FullContainerLoader />}>
                    <RelationLayoutView />
                  </Suspense>
                }
              />
              <Route
                path={ROUTERS_PATH.CONNECTIONS_RELATION_ABOUT}
                element={
                  <Suspense fallback={<FullContainerLoader />}>
                    <RelationAbout />
                  </Suspense>
                }
              />
              <Route
                path={ROUTERS_PATH.CONNECTIONS_RELATION_ABOUT_EDIT}
                element={
                  <Suspense fallback={<FullContainerLoader />}>
                    <RelationAboutEdit />
                  </Suspense>
                }
              />
              <Route
                path={ROUTERS_PATH.CONNECTIONS_RELATION_CONTACTS}
                element={
                  <Suspense fallback={<FullContainerLoader />}>
                    <RelationContacts />
                  </Suspense>
                }
              />
              <Route
                path={ROUTERS_PATH.CONNECTIONS_RELATION_CONTACTS_EDIT}
                element={
                  <Suspense fallback={<FullContainerLoader />}>
                    <RelationContactsEdit />
                  </Suspense>
                }
              />
              <Route
                path={ROUTERS_PATH.CONNECTIONS_RELATION_HEALTH_POLICIES}
                element={
                  <Suspense fallback={<FullContainerLoader />}>
                    <RelationHealthPolicies />
                  </Suspense>
                }
              />
              <Route
                path={ROUTERS_PATH.CONNECTIONS_RELATION_HEALTH_POLICIES_EDIT}
                element={
                  <Suspense fallback={<FullContainerLoader />}>
                    <RelationHealthPoliciesEdit />
                  </Suspense>
                }
              />
              <Route
                path={ROUTERS_PATH.CONNECTIONS_RELATION_HEALTH_POLICIES_ADD}
                element={
                  <Suspense fallback={<FullContainerLoader />}>
                    <RelationHealthPoliciesAdd />
                  </Suspense>
                }
              />
              <Route
                path={ROUTERS_PATH.CONNECTIONS_RELATION_RELATIONS}
                element={
                  <Suspense fallback={<FullContainerLoader />}>
                    <RelationRelations />
                  </Suspense>
                }
              />
              {/* NOTE temporary hide <Route path={ROUTERS_PATH.CONNECTIONS_RELATION_ORDERS} element={<RelationOrders />} /> */}
              <Route
                path={ROUTERS_PATH.CONNECTIONS_RELATION_RESULTS}
                element={
                  <Suspense fallback={<FullContainerLoader />}>
                    <RelationResults />
                  </Suspense>
                }
              />
              <Route
                path={ROUTERS_PATH.CONNECTIONS_RELATION_RESULT_DETAILS}
                element={
                  <Suspense fallback={<FullContainerLoader />}>
                    <ResultDetails />
                  </Suspense>
                }
              />
              <Route
                path={ROUTERS_PATH.CONNECTIONS_RELATION_RESULT_DYNAMICS}
                element={
                  <Suspense fallback={<FullContainerLoader />}>
                    <ResultDynamics />
                  </Suspense>
                }
              />
            </Route>
          </Route>
        </Route>
        <Route
          path={ROUTERS_PATH.INSURANCE}
          element={
            <Suspense fallback={<FullContainerLoader />}>
              <Insurance />
            </Suspense>
          }
        />
        <Route
          path={ROUTERS_PATH.ADD_INSURANCE}
          element={
            <Suspense fallback={<FullContainerLoader />}>
              <AddInsurance />
            </Suspense>
          }
        />
        <Route
          path={ROUTERS_PATH.EDIT_INSURANCE}
          element={
            <Suspense fallback={<FullContainerLoader />}>
              <EditInsurance />
            </Suspense>
          }
        />
        {/* NOTE temporary hide <Route path={ROUTERS_PATH.FAVORITES} element={<Favorites />} /> */}
        <Route
          path={ROUTERS_PATH.SETTINGS}
          element={
            <Suspense fallback={<FullContainerLoader />}>
              <Settings />
            </Suspense>
          }
        />
        <Route
          path={ROUTERS_PATH.STORAGE}
          element={
            <Suspense fallback={<FullContainerLoader />}>
              <Storage />
            </Suspense>
          }
        />
        <Route
          path={ROUTERS_PATH.STORAGE_DOCUMENT}
          element={
            <Suspense fallback={<FullContainerLoader />}>
              <StorageDocument />
            </Suspense>
          }
        />
        <Route path="*" element={<NotFound />} />
        <Route path="/403" element={<Forbidden />} />
      </Route>
    </Routes>
  );
};
