import {apiClient} from '@/client/ApiClient';

import {
  FetchGlobalNotificationsResponse,
  FetchGlobalNotificationsRequestParams,
  UpdateGlobalNotificationStatusBody,
} from './models';

export const getGlobalNotifications = async (params: FetchGlobalNotificationsRequestParams) => {
  const res = await apiClient.get<FetchGlobalNotificationsResponse[]>('/api/global-notifications', {
    params,
  });
  return res.data;
};

export const updateGlobalNotificationStatus = async ({
  requests,
}: UpdateGlobalNotificationStatusBody) => {
  const res = await apiClient.post<string>('/api/global-notifications/fn/mark-read', {
    requests,
  });
  return res.data;
};

export * as globalNotificationsApi from './';
export * from './models';
