import {LaboratoryResultDetail, ManualResultDetail, resultsApi} from '@/api';
import {ResultType} from '@/api/__generated__/webApi';
import i18n from '@/i18n/i18n';
import {envs} from '@/shared/constants/envs';
import {all, call, put, select, takeLeading} from 'redux-saga/effects';

import {errorHandler} from '../errorHandler';
import {notifyActions} from '../notifications/slice';
import {SagaPayload, SagaReturn} from '../types';

import {transformData} from './helpers';
import {resultsActions} from './slice';

export function * resultsSaga () {
  yield all([
    takeLeading(resultsActions.requestResults, requestResults),
    takeLeading(resultsActions.requestDynamics, requestDynamics),
    takeLeading(resultsActions.requestResultDetails, requestResultDetails),
    takeLeading(resultsActions.sendResultsByEmail, sendResultsByEmail),
    takeLeading(resultsActions.setDynamics, transformDynamicData),
  ]);
}

function * transformDynamicData (): SagaReturn {
  try {
    yield put(resultsActions.setFetching({results: true}));
    const dynamics = yield select((s) => s.results.dynamics);
    console.info('warn, transformData executed');
    const {testResults}: Awaited<ReturnType<typeof transformData>> = dynamics
      ? yield call(transformData, dynamics)
      : {testResults: []};
    yield put(resultsActions.setTranformedData(testResults));
  } catch (e) {
    errorHandler(e, resultsActions.setCommonError);
  } finally {
    yield put(resultsActions.setFetching({results: false}));
  }
}

function * requestResults (): SagaReturn {
  try {
    yield put(resultsActions.setFetching({results: true}));
    const results: Awaited<ReturnType<typeof resultsApi.getResults>> = yield call(
      resultsApi.getResults,
      {includeStorageResults: true},
    );
    yield put(resultsActions.setResults(results));
    yield put(resultsActions.setFilterResults(results));
  } catch (e: any) {
    if (e.status === 500 || !e.status) {
      yield put(
        notifyActions.showNotifications([
          {
            id: '',
            type: 'error',
            text: i18n.t('SMTH_WENT_WRONG', {link: envs.PATIENT_CONTACT_SUPPORT_URL}),
          },
        ]),
      );
    }
    errorHandler(e, resultsActions.setCommonError);
  } finally {
    yield put(resultsActions.setFetching({results: false}));
  }
}

function * requestDynamics () {
  try {
    yield put(resultsActions.setFetching({dynamics: true}));
    const dynamics: Awaited<ReturnType<typeof resultsApi.getDynamics>> = yield call(
      resultsApi.getDynamics,
    );
    yield put(resultsActions.setDynamics(dynamics));
  } catch (e) {
    errorHandler(e, resultsActions.setCommonError);
  } finally {
    yield put(resultsActions.setFetching({dynamics: false}));
  }
}

function * requestResultDetails ({
  payload: {resultId, type},
}: SagaPayload<typeof resultsActions.requestResultDetails>): SagaReturn {
  try {
    yield put(resultsActions.setFetching({details: true}));
    const details: Awaited<ReturnType<typeof resultsApi.getResultByid>> = yield call(
      resultsApi.getResultByid,
      {id: resultId, type},
    );
    if (details.type === ResultType.MANUAL) {
      yield put(resultsActions.setManualDetails(details as ManualResultDetail));
    }
    if (details.type === ResultType.LABORATORY) {
      yield put(resultsActions.setLaboratoryDetails(details as LaboratoryResultDetail));
    }
  } catch (e: any) {
    yield put(resultsActions.setErrorStatus(e.status));
    if (e.status === 500 || !e.status) {
      yield put(
        notifyActions.showNotifications([
          {
            id: '',
            type: 'error',
            text: i18n.t('SMTH_WENT_WRONG', {link: envs.PATIENT_CONTACT_SUPPORT_URL}),
          },
        ]),
      );
    }
    errorHandler(e, resultsActions.setCommonError);
  } finally {
    yield put(resultsActions.setFetching({dynamics: false, details: false}));
  }
}

function * sendResultsByEmail ({
  payload,
}: SagaPayload<typeof resultsActions.sendResultsByEmail>): SagaReturn {
  try {
    yield call(resultsApi.sendResultsByEmail, {
      params: {id: payload.resultId, type: payload.type},
      data: {email: payload.email},
    });
    yield put(resultsActions.setStep({step: 'success'}));
  } catch (e) {
    yield put(resultsActions.setStep({step: 'error'}));
    errorHandler(e, resultsActions.setCommonError);
  }
}
