import {bmiApi} from '@/api';
import {all, takeLeading, call, SagaReturnType, put} from 'redux-saga/effects';

import {errorHandler} from '../errorHandler';
import {SagaPayload} from '../types';

import {bmiActions as actions} from './slice';
export function * bmiSaga () {
  yield all([
    takeLeading(actions.requestGetBMI, requestGetBMI),
    takeLeading(actions.requestCreateBmi, requestCreateBMI),
  ]);
}

export function * requestGetBMI (): SagaReturnType<any> {
  try {
    yield put(actions.setFetching({bmi: true}));
    const bmiData: Awaited<ReturnType<typeof bmiApi.getBmiData>> = yield call(bmiApi.getBmiData);
    yield put(actions.setBmiData(bmiData.data));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setFetching({bmi: false, recommendation: false}));
  }
}

export function * requestCreateBMI ({payload}: SagaPayload<typeof actions.requestCreateBmi>): SagaReturnType<any> {
  try {
    const response: Awaited<ReturnType<typeof bmiApi.createBmi>> = yield call(bmiApi.createBmi, payload);
    if (response.status === 200) {
      yield put(actions.setStep({step: 'success'}));
      yield put(actions.requestGetBMI());
    } else {
      yield put(actions.setStep({step: 'error'}));
    }
  } catch (e) {
    errorHandler(e, actions.setCommonError);
    yield put(actions.setStep({step: 'error'}));
  } finally {
    yield put(actions.setFetching({bmi: false}));
  }
}
