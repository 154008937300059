import {ResultDetail, ResultTests} from '@/api';
import {apiClient} from '@/client/ApiClient';
import {UUID} from '@/types';

import {
  FetchHealthCasesRequestParams,
  FetchHealthCasesResponse,
  HealthCase,
  HealthCaseCreateProps,
  HealthCaseData,
  HealthCaseFile,
  ResultIdParams,
} from './models';
import {HealthCaseObject} from '@/api/__generated__/webApi';

const HEALTH_CASES_URL = '/api/health-cases';
const HEALTH_CASE_URL = '/api/health-case-objects';

export const getHealthCases = async (params: FetchHealthCasesRequestParams) => {
  const response = await apiClient.get<FetchHealthCasesResponse>(`${HEALTH_CASES_URL}/search`, {
    params,
  });
  return response.data;
};

/**
 * @description Get health case object by health case id
 *
 * @tags HealthCaseObjects
 * @name GetHealthCaseObjectByHealthCaseId
 * @summary Get health case object by health case id
 * @request GET:/api/health-case-objects/fn/get-by-health-case-id/{id}
 * @docs https://patient-web.test.private.lifedl.net/swagger-ui/index.html#HealthCaseObjects/getHealthCaseObjectByHealthCaseId
 * @response `200` `(HealthCaseObject)[]` OK
 * @response `401` `undefined` Unauthorized
 * @response `500` `BaseError` Internal Server Error
 */
export const getHealthCaseFiles = async (id: HealthCase['id']) => {
  const response = await apiClient.get<HealthCaseObject[]>(
    `${HEALTH_CASE_URL}/fn/get-by-health-case-id/${id}`
  );
  return response.data;
};

export const getHealthCaseData = async (id: HealthCase['id']) => {
  const response = await apiClient.get<HealthCaseData>(`${HEALTH_CASES_URL}/${id}`);
  return response.data;
};

export const createHealthCase = async (data: HealthCaseCreateProps) => {
  const response = await apiClient.post<HealthCase>(`${HEALTH_CASES_URL}`, data);
  return response.data;
};

export const updateHealthCase = async (id: HealthCase['id'], data: HealthCaseCreateProps) => {
  const response = await apiClient.post<HealthCase>(`${HEALTH_CASES_URL}/${id}`, data);
  return response.data;
};

export const addResultToHealthCase = async ({
  resultId,
  healthCaseId,
}: {
  resultId: UUID;
  healthCaseId: UUID;
}) => {
  const response = await apiClient.post<HealthCase>(`/api/results/${resultId}/fn/add-health-case`, {
    healthCaseId,
  });
  return response.data;
};

export const addDocumentToHealthCase = async ({
  storageId,
  healthCaseId,
}: {
  storageId: UUID;
  healthCaseId: UUID;
}) => {
  const response = await apiClient.post<HealthCase>(
    `/api/storage/${storageId}/fn/add-health-case`,
    {healthCaseId}
  );
  return response.data;
};

export const getResultByid = async ({resultId}: ResultIdParams) => {
  const response = await apiClient.get<ResultDetail>(`/api/results/${resultId}`);
  return response.data;
};

export const getResultsTests = async ({resultId}: ResultIdParams) => {
  const response = await apiClient.get<ResultTests[]>(`/api/results/${resultId}/tests`);
  return response.data;
};

export const deleteHealthCase = async (id: HealthCase['id']) => {
  const response = await apiClient.delete(`${HEALTH_CASES_URL}/${id}`);
  return response.status;
};

export const deleteHealthCaseFile = async (id: HealthCaseFile['id']) => {
  const response = await apiClient.delete(`${HEALTH_CASE_URL}/${id}`);
  return response.status;
};

export * as healthCasesApi from './';
export * from './models';
