import {favoritesApi} from '@/api';
import {all, takeLeading, call, SagaReturnType, put} from 'redux-saga/effects';
import {favoritesActions as actions} from './slice';
import {SagaPayload, SagaReturn} from '../types';
import {errorHandler} from '../errorHandler';

export function * favoritesSaga () {
  yield all([
    takeLeading(actions.requestDoctors, requestDoctors),
    takeLeading(actions.deleteFavoriteDoctorRequest, deleteFavoriteDoctorRequest),
  ]);
}

export function * requestDoctors (): SagaReturnType<any> {
  try {
    yield put(actions.setFetching({favorites: true}));
    const doctors: Awaited<ReturnType<typeof favoritesApi.getFavoritesDoctors>> = yield call(favoritesApi.getFavoritesDoctors);
    yield put(actions.setDoctors({doctors}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setFetching({favorites: false}));
  }
}

function * deleteFavoriteDoctorRequest ({
  payload,
}: SagaPayload<typeof actions.deleteFavoriteDoctorRequest>): SagaReturn {
  try {
    yield call(favoritesApi.deleteFavoriteDoctor, {id: payload.doctorId});
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  }
}
