import {SxProps, Theme, styled, Stack} from '@mui/system';
import {initialColors} from '@/theme';

import {calcDefaultPaddings, calcPaddingDownLg, calcPaddingDownMd, calcPaddingDownSm} from './helpers';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  newStackWrapper: {
    color: 'grey.400',
    flexGrow: 1,
    flexDirection: 'row',
    padding: {
      xs: 0,
      sm: '0 24px 24px',
      md: '0 14px 14px',
      xl: '0 24px 24px',
    },
    alignItems: 'center',
    gap: {
      xs: 8,
      sm: 4,
    },
  },
  stackWrapper: {
    flexDirection: 'row',
    color: 'grey.400',
    padding: {
      xs: '18px 24px 8px',
      sm: '42px 0',
    },
    gap: {
      xs: 8,
      sm: 4,
    },
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
  },
};

export const NewStackWrapper = styled(Stack)<{
  isLoading?: boolean
  isConnections?: boolean
  isResults?: boolean
  isDynamics?: boolean
  isDetails?: boolean
}>(({theme, isLoading, isConnections, isResults, isDynamics, isDetails}) => ({
  borderTop: isConnections ? `1px solid ${initialColors.blueLight}` : 'none',
  height: isConnections ? 'calc(100vh - 420px)' : 'auto',
  paddingTop: isConnections ? 12 : 0,
  justifyContent: isLoading ? 'center' : 'start',
  color: 'grey.400',
  flexGrow: 1,
  flexDirection: 'row',
  padding: calcDefaultPaddings(isDynamics, isResults, isConnections, isDetails),
  [theme.breakpoints.down('xl')]: {
    height: isDetails ? '100%' : 'calc(100vh - 385px)',
  },
  [theme.breakpoints.down('lg')]: {
    paddingTop: isConnections ? 12 : 0,
    height: isDetails ? '100%' : 'calc(100vh - 430px)',
    padding: calcPaddingDownLg(isDynamics, isResults, isDetails),
  },
  [theme.breakpoints.down('md')]: {
    padding: calcPaddingDownMd(isConnections, isResults, isDynamics),
    gap: 4,
  },
  [theme.breakpoints.down('sm')]: {
    padding: calcPaddingDownSm(isDynamics, isConnections, isDetails),
    gap: 8,
    height: isConnections && !isDynamics ? 'calc(100vh - 450px)' : 'calc(100vh - 400px)',
    border: isResults || isDynamics ? 'none' : `1px solid ${initialColors.blueLight}`,
  },
}));
