import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ValidationErrorType} from '@/types';

export interface MessengerState {
  unreadCount: number | null
  error?: ValidationErrorType | null
}
const initialState: MessengerState = {
  unreadCount: null,
};

export const messenger = createSlice({
  name: 'messenger',
  initialState,
  reducers: {
    requestMessengerStatistic () {},
    requestConnectionConfigure () {},
    requestConnectionClose (_state, _action: PayloadAction<{id: string}>) {},
    setMessengerData (state, {payload}: PayloadAction<{count: number}>) {
      state.unreadCount = payload.count;
    },
    setCommonError (state, {payload}: PayloadAction<ValidationErrorType | null>) {
      state.error = payload;
    },
  },
});

export const messengerReducer = messenger.reducer;
export const messengerActions = messenger.actions;
export const messengerStoreName = messenger.name;
