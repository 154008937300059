import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {storageApi, StorageFileCreateProps, StorageFileUpdateProps} from '@/api';
import {StorageFileCreateRequest, StorageFileEditRequest} from '@/api/__generated__/webApi';
import i18n from '@/i18n/i18n';
import {envs} from '@/shared/constants/envs';
import {getError} from '@/store/common/error-handlers';
import {errorHandler} from '@/store/errorHandler';
import {notifyActions} from '@/store/notifications/slice';

import {INITIAL_FILTERS} from './constants';
import {
  DeleteStorageFileProps,
  FetchStorageFilesProps,
  SetFiltersProps,
  StorageState,
  AddHealthCaseProps,
} from './types';

const initialState: StorageState = {
  files: {
    hasNext: true,
    items: [],
    page: 0,
    filters: INITIAL_FILTERS,
  },
  isLoading: false,
  isInitialLoading: true,
  errorStatus: null,
  errorText: '',
  selectedHealthCases: [],
};

export const fetchStorageFiles = createAsyncThunk(
  'storage/fetchStorageFiles',
  async ({isNext, ...payload}: FetchStorageFilesProps, {rejectWithValue}) => {
    try {
      return {data: await storageApi.getStorageFiles(payload), isNext};
    } catch (e) {
      const error = getError(e);
      errorHandler(error);
      return rejectWithValue(error);
    }
  },
);

export const updateStorageFile = createAsyncThunk(
  'storage/updateStorageFile',
  async ({showSuccessToast, errorToastText, id, ...payload}: StorageFileUpdateProps, {rejectWithValue, dispatch}) => {
    try {
      const {date, description, favorite, name, ...otherData} = payload;
      const data: StorageFileEditRequest = {
        date: {
          value: date,
        },
        description: {
          value: description,
        },
        favorite: {
          value: favorite,
        },
        name: name
          ? {
            value: name,
          }
          : undefined,
        ...otherData,
      };
      const response = await storageApi.updateStorageFile(id, {data});
      if (showSuccessToast) {
        dispatch(
          notifyActions.showNotifications([
            {id, type: 'success', text: i18n.t('DOCUMENT_SAVED')},
          ]),
        );
      }
      return response;
    } catch (e) {
      const error = getError(e);
      errorHandler(error);
      if (errorToastText) {
        dispatch(
          notifyActions.showNotifications([
            {id, type: 'error', text: errorToastText},
          ]),
        );
      }
      return rejectWithValue(error);
    }
  },
);

export const deleteStorageFile = createAsyncThunk(
  'storage/deleteStorageFile',
  async (payload: DeleteStorageFileProps, {rejectWithValue, dispatch}) => {
    try {
      await storageApi.deleteStorageFile(payload.id);
      dispatch(
        notifyActions.showNotifications([
          {id: payload.id, type: 'success', text: payload.successText},
        ]),
      );
      return payload.id;
    } catch (e) {
      const error = getError(e);
      dispatch(
        notifyActions.showNotifications([
          {
            id: '',
            type: 'error',
            text: i18n.t('SMTH_WENT_WRONG', {link: envs.PATIENT_CONTACT_SUPPORT_URL}),
          },
        ]),
      );
      return rejectWithValue(error);
    }
  },
);

export const addStorageFile = createAsyncThunk(
  'storage/addStorageFile',
  async (payload: StorageFileCreateProps, {rejectWithValue, dispatch}) => {
    try {
      const {date, description, favorite, name, category, file: _, ...otherData} = payload;
      const data: StorageFileCreateRequest = {
        date: {
          value: date,
        },
        description: {
          value: description,
        },
        favorite: {
          value: favorite,
        },
        name: {
          value: name,
        },
        // TODO: Correct EditResultDialog types
        // eslint-disable-next-line
        // @ts-expect-error
        category,
        ...otherData,
      };
      const newFile = await storageApi.createStorageFile({file: payload.file, data});
      dispatch(
        notifyActions.showNotifications([
          {id: newFile.id, type: 'success', text: i18n.t('DOCUMENT_SAVED')},
        ]),
      );
      return newFile;
    } catch (e) {
      const error = getError(e);
      errorHandler(error);
      return rejectWithValue(error);
    }
  },
);

export const storage = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    setFilters: (state, {payload}: PayloadAction<SetFiltersProps>) => {
      if (payload.updatedValue) {
        state.files.filters = {
          ...state.files.filters,
          [payload.updatedValue.key]: payload.updatedValue.value,
        };
      }
      if (payload.newState) {
        state.files.filters = payload.newState;
      }
    },
    setSelectedHealthCases: (state, {payload}: PayloadAction<string[]>) => {
      state.selectedHealthCases = payload;
    },
    clearSelectedHealthCases: (state) => {
      state.selectedHealthCases = [];
    },
    clearIsNew: (state) => {
      state.files.items = state.files.items.map((item) => ({...item, isNew: false}));
    },
    setErrorText: (state, {payload}: PayloadAction<string>) => {
      state.errorText = payload;
    },
    setErrorStatus (state, {payload}: PayloadAction<number | null>) {
      state.errorStatus = payload;
    },
    addHealthCase: (state, {payload}: PayloadAction<AddHealthCaseProps>) => {
      state.files.items = state.files.items.map((document) =>
        document.id === payload.id
          ? {...document, healthCases: [...document.healthCases, payload.healthCase]}
          : document,
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStorageFiles.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchStorageFiles.fulfilled, (state, action) => {
      if (action.payload) {
        const {data, isNext} = action.payload;
        state.files = isNext
          ? {
            ...state.files,
            hasNext: data.hasNext,
            page: state.files.page + 1,
            items: [...state.files.items, ...data.items],
          }
          : {...data, filters: state.files.filters, page: 1};
      }
      state.isLoading = false;
      state.errorStatus = null;
      state.isInitialLoading = false;
    });
    builder.addCase(updateStorageFile.fulfilled, (state, {payload}) => {
      if (payload) {
        state.files.items = state.files.items.map((item) => {
          if (item.id === payload.id) {
            return payload;
          }
          return item;
        });
      }
    });
    builder.addCase(deleteStorageFile.fulfilled, (state, {payload}) => {
      if (payload) {
        state.files.items = state.files.items.filter((item) => item.id !== payload);
      }
    });
    builder.addCase(addStorageFile.fulfilled, (state, {payload}) => {
      state.files.items = [{...payload, isNew: true}, ...state.files.items];
    });
  },
});

export const storageReducer = storage.reducer;
export const storageActions = storage.actions;
export const storageStoreName = storage.name;
