import {envs} from '@/shared/constants/envs';
import {useEffect} from 'react';

export const getRecaptchaToken = async (): Promise<string> => {
  if (!envs.GOOGLE_RECAPTCHA_KEY) {
    const errorMessage = 'Google reCAPTCHA key is missing!';
    console.error(errorMessage);
    return await Promise.reject(errorMessage);
  }

  return await new Promise((resolve, reject) => {
    grecaptcha.ready(() => {
      grecaptcha.execute(envs.GOOGLE_RECAPTCHA_KEY, {action: 'submit'})
        .then(
          (token: string) => {
            resolve(token);
          },
          (e) => reject(e));
    });
  });
};

export const useRecaptcha = () => {
  useEffect(() => {
    // TODO Add reCaptcha
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${envs.GOOGLE_RECAPTCHA_KEY}`;
    document.body.appendChild(script);
    return () => {
      try {
        document.body.removeChild(script);
        delete (window as any).grecaptcha;
        const el = document.body.getElementsByClassName('grecaptcha-badge')?.[0]?.parentNode;
        if (el) {
          document.body.removeChild(el as Node);
        }
      } catch (e) {
        console.error(e);
      }
    };
  }, []);
  return getRecaptchaToken;
};
