import {ResultsFilters} from './types.ts';

export const initialFilters: ResultsFilters = {
  collectionDateFrom: null,
  collectionDateTo: null,
  physician: null,
  reportDateFrom: null,
  reportDateTo: null,
  healthCase: [],
  sort: {field: '', desc: true},
};
