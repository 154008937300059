import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AccountDetails, Dashboard, ProfileUpdatedData} from '@/api';
import {Profile} from '@/api/__generated__/webApi';
import {PartialRecord, ValidationErrorType} from '@/types';

type Fetching = 'common' | 'account' | 'dashboard' | 'profile';
export interface AccountState {
  account: AccountDetails | null
  dashboard: {dashboard: Dashboard, interval: number} | null
  accountProfile: Profile | null
  fetching: PartialRecord<Fetching, boolean> | null
  error?: ValidationErrorType | null
}
const initialState: AccountState = {
  account: null,
  dashboard: null,
  accountProfile: null,
  fetching: null,
};

export const account = createSlice({
  name: 'account',
  initialState,
  reducers: {
    requestAccount () {},
    requestDashboardData (_state, _action: PayloadAction<{dashboardInterval: number}>) {},
    requestAccountProfileData () {},
    updateAccountProfile (_state, _payload: PayloadAction<{data: ProfileUpdatedData}>) {},
    setAccountData (state, {payload}: PayloadAction<{account: AccountDetails}>) {
      state.account = payload.account;
    },
    setDashboardData (state, {payload}: PayloadAction<{dashboard: Dashboard, interval: number}>) {
      state.dashboard = {dashboard: payload.dashboard, interval: payload.interval};
    },
    setAccountProfileData (state, {payload}: PayloadAction<{accountProfile: Profile}>) {
      state.accountProfile = payload.accountProfile;
    },
    doLogout () {},
    setFetching (state, {payload}: PayloadAction<AccountState['fetching']>) {
      if (!payload) {
        state.fetching = null;
      }
      state.fetching = {...state.fetching, ...payload};
    },
    setCommonError (state, {payload}: PayloadAction<ValidationErrorType | null>) {
      state.error = payload;
    },
  },
});

export const accountReducer = account.reducer;
export const accountActions = account.actions;
export const accountStoreName = account.name;
